import { createProductStart, updateProductStart } from 'actions';
import { enums } from 'enums';

import { Periods } from '../../../constants/Periods';
export const displayPlatinumUI =
  process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';

export const defaultPeriodValue = displayPlatinumUI
  ? Periods['semester'].value
  : Periods['month'].value;

export const defaultUnitValue = displayPlatinumUI
  ? enums.Units.GRAMS
  : enums.Units.KWH;

type ProcessInfoArrayType = {
  standards: any[];
  values: any;
};

export const processInfoArray = ({
  standards,
  values,
}: ProcessInfoArrayType) => {
  const selectedStandard = standards.find(
    (s: any) => s._id === values.standard
  );

  const infoArray: any[] = [];
  if (selectedStandard) {
    const standardInfoTexts = selectedStandard?.infoTexts;
    standardInfoTexts.forEach((infoText: any, index: number) => {
      const {
        infoStandardText: label,
        infoStandardDesc: desc,
        infoProp: key,
        type,
        listItems,
        unit,
        sourceName,
        tableTitle,
        collection,
      } = infoText;

      infoArray.push({
        label,
        desc,
        pos: index,
        type,
        listItems,
        unit,
        sourceName,
        tableTitle,
        key,
        collection,
      });
    });
  }
  return infoArray;
};

export const handleOnValidate = ({
  standards,
  values,
}: ProcessInfoArrayType) => {
  const errors: any = {};

  const selectedStandard = standards.find((s) => s._id === values.standard);

  selectedStandard?.infoTexts.forEach((infoText: any) => {
    const label = infoText?.infoStandardText;
    const isDynamicData = infoText?.type === 'dynamicData';
    if (!values[infoText.infoProp] && label && !isDynamicData) {
      errors[infoText.infoProp] = 'Required';
    }
  });

  if (!values.displayedName) {
    errors.displayedName = 'Required';
  }
  if (!values.standard) {
    errors.standard = 'Required';
  }
  if (!values.organization) {
    errors.organization = 'Required';
  }
  if (!values.unit) {
    errors.unit = 'Required';
  }
  if (!values.timezone) {
    errors.timezone = 'Required';
  }
  if (typeof values.deprecated !== 'boolean') {
    errors.deprecated = 'Must be Boolean';
  }
  return errors;
};

type SubmitPropsType = {
  organizations: any;
  standards: any;
  currentProduct: any;
  localProductReferenceFiles: any;
  selectedWells: any;
  localProductPicture: any;
  navigate: any;
  sourceHistory: any;
  assetId: any;
  values: any;
  dispatch: any;
};

export const handleOnSubmit = async ({
  organizations,
  values,
  standards,
  currentProduct,
  localProductReferenceFiles,
  selectedWells,
  localProductPicture,
  navigate,
  sourceHistory,
  assetId,
  dispatch,
}: SubmitPropsType) => {
  const organization = organizations.find((org: any) => {
    return org._id === values.organization;
  });

  const selectedStandard = standards.find(
    (s: any) => s._id === values.standard
  );
  const productData = {
    ...values,
    standardValues:
      selectedStandard?.infoTexts.map((infoText: any) => ({
        key: infoText.infoProp,
        value: values[infoText.infoProp] || '',
      })) || [],
  };
  // Remove the infoX properties from the productData
  standards
    .find((s: any) => s._id === values.standard)
    ?.infoTexts.forEach((infoText: any) => {
      delete productData[infoText.infoProp];
    });

  const completeProduct = {
    ...productData,
    organization: organization,
    standard: selectedStandard,
    wells: selectedWells,
    assetID: values.assetID ? values.assetID : values.productId,
  };

  if (currentProduct && currentProduct._id) {
    completeProduct._id = currentProduct._id;
  }

  const localProductReferenceFilesToUpload =
    localProductReferenceFiles &&
    localProductReferenceFiles.map((f: any) => {
      f.file.validityStart = f.validityStart;
      f.file.validityEnd = f.validityEnd;
      f.file.notify = f.notify;
      return f.file;
    });

  const productArgs = {
    product: completeProduct,
    file: localProductPicture,
    referenceFiles: localProductReferenceFilesToUpload,
    navigate,
    sourceHistory,
  };

  assetId
    ? await dispatch(updateProductStart(productArgs))
    : await dispatch(createProductStart(productArgs));
};

type FormInitialValuesPropsType = {
  currentProduct: any;
  standards: any;
  organizations: any;
};

export const processFormInitialValues = ({
  currentProduct,
  standards,
  organizations,
}: FormInitialValuesPropsType) => {
  const defaultTimeZone = displayPlatinumUI
    ? 'America/New_York'
    : 'Europe/Paris';
  const formInitialValues: any = {
    displayedName:
      currentProduct && currentProduct.displayedName
        ? currentProduct.displayedName
        : '',
    productId:
      currentProduct && currentProduct.productId
        ? currentProduct.productId
        : '',
    assetID:
      currentProduct && currentProduct.assetID ? currentProduct.assetID : '',
    organization:
      currentProduct &&
      currentProduct.organization &&
      currentProduct.organization._id
        ? currentProduct.organization._id
        : organizations[0]?._id,

    productType: currentProduct?.standard?.productType?._id
      ? currentProduct?.standard?.productType._id
      : standards[0]?.productType?._id,
    standard:
      currentProduct && currentProduct.standard && currentProduct.standard._id
        ? currentProduct.standard._id
        : standards[0]?._id,

    deprecated:
      currentProduct && currentProduct.deprecated
        ? currentProduct.deprecated
        : false,
    wells:
      currentProduct && currentProduct.wells ? currentProduct.wells : false,
    deprecationComment:
      currentProduct &&
      currentProduct.deprecated &&
      currentProduct.deprecationComment
        ? currentProduct.deprecationComment
        : '',
    unit:
      currentProduct && currentProduct.unit
        ? currentProduct.unit
        : defaultUnitValue,
    timezone:
      currentProduct && currentProduct.timezone
        ? currentProduct.timezone
        : defaultTimeZone,
  };

  const selectedStandard = standards?.find(
    (s: any) => s._id === formInitialValues.standard
  );
  selectedStandard?.infoTexts.forEach((infoText: any) => {
    formInitialValues[infoText.infoProp] =
      currentProduct?.standardValues?.find(
        (sv: any) => sv.key === infoText.infoProp
      )?.value || '';
  });

  return formInitialValues;
};
