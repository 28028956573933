import { useEffect, useState } from 'react';

import { GridColDef } from '@mui/x-data-grid-pro';
import DateCell from 'components/commons/DateCell';
import LinkOpenProductDialog from 'components/commons/LinkOpenProductDialog/LinkOpenProductDialog';
import useTransactionsCombiner from 'components/Transactions/Redux/Slices/Combiner/useTransactionsCombiner';
import { enums } from 'enums';
import moment from 'moment';
import { convertGramsInOz } from 'utils/convert';
import {
  certificateQuantityRenderer,
  getCertificateDateFromMonthAndYearText,
  getCertificateFromName,
  getCertificateOriginName,
  getCertificateOriginUnit,
  getCertificateProductId,
  getCertificateQuantity,
  getCertificateToName,
  getTransactionTypeName,
  getTransactionUser,
  monthYearComparator,
} from 'utils/datagridHelpers';
import { periodTitle, quantityTitle } from '../constants';

import { getDynamicColumns } from './dynamicColumns';
import TransactionAndSecretOptions from 'components/Certificates/CustomComponents/Table/Options/TransactionAndSecretOptions';
import { handleOpenSecretWordDialog } from 'components/Certificates/CustomComponents/Table/utils/utils';
import { AppDispatch } from 'redux/store';
import { useDispatch } from 'react-redux';

const TransactionColumns = (): GridColDef[] => {
  const dispatch: AppDispatch = useDispatch();
  const {
    ozOrGramsQuantityUnit,
    selectedStandardId,
    selectedStandardType,
    uniqueStandardsFromBalances,
    aggregationId,
  } = useTransactionsCombiner();
  const [dynamicDefaultColumns, setDynamicDefaultColumns] = useState<
    Array<GridColDef | undefined>
  >([]);
  const [dynamicColumns, setDynamicColumns] = useState<
    Array<GridColDef | undefined>
  >([]);

  useEffect(() => {
    setDynamicDefaultColumns(
      getDynamicColumns(
        selectedStandardId,
        uniqueStandardsFromBalances,
        'default'
      )
    );
  }, [selectedStandardId, uniqueStandardsFromBalances]);

  useEffect(() => {
    setDynamicColumns(
      getDynamicColumns(selectedStandardType, uniqueStandardsFromBalances)
    );
  }, [selectedStandardType, uniqueStandardsFromBalances]);

  let dataGridColumns: GridColDef[] = [
    {
      field: 'typeName',
      headerName: 'Action',
      editable: false,
      width: 200,
      valueGetter: getTransactionTypeName,
    },

    {
      field: 'fromName',
      headerName: 'From',
      editable: false,
      width: 200,
      valueGetter: getCertificateFromName,
    },
    {
      field: 'toName',
      headerName: 'To',
      editable: false,
      width: 200,
      valueGetter: getCertificateToName,
    },

    {
      field: 'creationDate',
      headerName: 'Date',
      editable: false,
      width: 120,
      type: 'dateTime',
      valueFormatter: ({ value }: any) =>
        value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '',
      renderCell: (params: any) => (
        <DateCell
          date={params.row.creationDate}
          cancelled={params.row.cancelled}
        />
      ),
    },
    {
      field: 'productIdValue',
      headerName: 'Product Id',
      editable: false,
      width: 200,
      valueGetter: getCertificateProductId,
      renderCell: (params) => {
        return (
          <LinkOpenProductDialog
            product={params?.row?.origin}
            month={params?.row?.month}
            year={params?.row?.year}
            text={params?.row?.origin?.productId}
          />
        );
      },
    },
    {
      field: 'productNameValue',
      headerName: 'Product',
      editable: false,
      width: 200,
      valueGetter: getCertificateOriginName,
      renderCell: (params) => {
        return (
          <LinkOpenProductDialog
            product={params?.row?.origin}
            month={params?.row?.month}
            year={params?.row?.year}
            text={params?.row?.origin?.displayedName}
          />
        );
      },
    },
    ...(dynamicColumns as GridColDef[]),
    ...(dynamicDefaultColumns as GridColDef[]),
    {
      field: 'volume',
      headerName: quantityTitle,
      editable: false,
      width: 140,
      type: 'number',
      valueGetter: (params: any) => {
        const _quantity = getCertificateQuantity(params);
        const productUnit = getCertificateOriginUnit(params);
        if (
          process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum' &&
          productUnit === enums.Units.GRAMS &&
          ozOrGramsQuantityUnit !== enums.Units.GRAMS
        ) {
          return convertGramsInOz(_quantity);
        }
        return _quantity;
      },
      valueFormatter: ({ value }: any) => parseInt(value),
      renderCell: certificateQuantityRenderer,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      editable: false,
      width: 80,
      valueGetter: (params: any) => {
        const productUnit = getCertificateOriginUnit(params);
        if (
          process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum' &&
          productUnit === enums.Units.GRAMS &&
          ozOrGramsQuantityUnit !== enums.Units.GRAMS
        ) {
          return 'oz';
        }
        return productUnit;
      },
    },

    {
      field: 'monthAndYear',
      headerName: periodTitle,
      editable: false,
      width: 120,
      type: 'string',
      sortComparator: monthYearComparator,
      valueGetter: getCertificateDateFromMonthAndYearText,
    },

    {
      field: 'blockchainProof',
      headerName: 'Blockchain proof',
      editable: false,
      width: 140,
      filterable: false,
      sortable: false,
      disableExport: true,
      renderCell: (params: any) => (
        <TransactionAndSecretOptions
          txHash={params.row.txHash}
          onSecretClick={async () => {
            await dispatch(handleOpenSecretWordDialog(params.row));
          }}
        />
      ),
    },
    {
      field: 'user',
      headerName: 'User',
      editable: false,
      width: 200,
      valueGetter: getTransactionUser,
    },
  ];
  if (aggregationId) {
    dataGridColumns = dataGridColumns.filter(
      (column) =>
        column.field !== 'fromName' &&
        column.field !== 'toName' &&
        column.field !== 'secret' &&
        column.field !== 'issuerName' &&
        column.field !== 'typeName' &&
        column.field !== 'user'
    );
  }

  return dataGridColumns;
};

export default TransactionColumns;
