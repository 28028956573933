import React from 'react';
import MenuItemIcon from 'components/commons/MenuItemIcon/MenuItemIcon';
import eyeIcon from '../../../../../../../../assets/images/icons/eye8.png';
import copyIcon from '../../../../../../../../assets/images/icons/copy.png';
import blockchainLogo from '../../../../../../../../assets/images/blockchain.png';

type DataBlockchainProofsListProps = {
  period: string;
  goldenSourceDynamicDataHash: string | null;
  txHash: string | null;
  onPreviewData: () => void;
  onCopyDataHash: () => void;
  onViewTransaction: () => void;
};

const DataBlockchainProofsList: React.FC<DataBlockchainProofsListProps> = ({
  period,
  goldenSourceDynamicDataHash,
  txHash,
  onPreviewData,
  onCopyDataHash,
  onViewTransaction,
}) => {
  return (
    <>
      {period && goldenSourceDynamicDataHash && (
        <MenuItemIcon
          key="previewSourceData"
          icon={eyeIcon}
          onClick={onPreviewData}
          title="Data"
        />
      )}
      {period && goldenSourceDynamicDataHash && (
        <MenuItemIcon
          key="copyDataHash"
          icon={copyIcon}
          onClick={onCopyDataHash}
          title="Data hash"
        />
      )}
      {period && txHash && (
        <MenuItemIcon
          key="linkToTx"
          icon={blockchainLogo}
          onClick={onViewTransaction}
          title="Data transaction"
        />
      )}
    </>
  );
};

export default DataBlockchainProofsList;
