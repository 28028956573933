import * as math from 'mathjs';
import moment from 'moment';

import { enums } from '../../../../enums';
import { convertGramsInOz, getMostUsedUnit } from '../../../../utils/convert';
import { getCertificateInfo } from '../../../../utils/datagridHelpers';

let displayPlatinumUI = process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';

let quantityUnit = '';

export const dateRange = (startDate, endDate) => {
  var start = startDate.split('-');
  var end = endDate.split('-');
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? '0' + month : month;
      dates.push([i, displayMonth, '01'].join('-'));
    }
  }
  return dates;
};

export const getLabels = (startDate, endDate) => {
  let labels = [];
  let startDateForLabels = moment(startDate).format('YYYY-MM-DD');
  let endDateForLabels = moment(endDate).format('YYYY-MM-DD');
  let dates = dateRange(startDateForLabels, endDateForLabels);

  dates.forEach((date) => {
    labels.push(moment(date, 'YYYY-MM-DD').format('YYYY-MM'));
  });
  return labels;
};

export const getAggregatedBalancesByMonth = (
  balances,
  user,
  ozOrGramsQuantityUnit,
  startDate,
  endDate
) => {
  let labels = getLabels(startDate, endDate);
  const mainUnits = balances.map((balance) => balance._origin.unit);

  let unitToUse = getMostUsedUnit(mainUnits);

  let allocatedQuantityValues = [];
  let quantityValues = [];

  labels.forEach((label, index) => {
    quantityValues[index] = 0;
    allocatedQuantityValues[index] = 0;
    balances.forEach((balance) => {
      if (
        moment(balance._year + '-' + balance._month, 'YYYY-MM').format(
          'YYYY-MM'
        ) === label
      ) {
        if (
          (balance._origin.unit === enums.Units.KWH &&
            unitToUse === enums.Units.KWH) ||
          (balance._origin.unit === enums.Units.MMBTU &&
            unitToUse === enums.Units.MMBTU)
        ) {
          quantityValues[index] += balance._quantity;
          allocatedQuantityValues[index] += balance._allocatedQuantity;
        }
      }
    });
  });

  let maxQuantity = Math.max(...quantityValues);
  quantityUnit = unitToUse;

  let maxAllocatedQuantity = Math.max(...allocatedQuantityValues);

  let allocatedUnit = quantityUnit;
  maxAllocatedQuantity = Math.max(...allocatedQuantityValues);
  maxQuantity = Math.max(...quantityValues);
  return {
    quantityValues,
    maxQuantity,
    quantityUnit,
    allocatedQuantityValues,
    maxAllocatedQuantity,
    allocatedUnit,
  };
};

export const formatMonthYear = (value) => {
  let valueToRender = moment(value, 'YYYY-MM').format('MMM-YYYY');
  if (displayPlatinumUI) {
    let year = moment(value, 'YYYY-MM').format('YY');
    let month = parseInt(moment(value, 'YYYY-MM').format('M'));
    let halfYear = 1;
    if (month > 6) halfYear = 2;
    valueToRender = `${halfYear}H${year}`;
  }

  return valueToRender;
};

export const getAllocatedInitialVolume = (
  location,
  allocateCertificatesData,
  activateOzConversion
) => {
  let initialVolume = location?.state?.allocateVolume
    ? location?.state?.allocateVolume
    : allocateCertificatesData?._volume; //set popup values with location state if available
  let volumeToSend = activateOzConversion
    ? parseInt(convertGramsInOz(initialVolume))
    : initialVolume;
  return volumeToSend;
};

export const addThousandsSeparatorToNumber = (num) => {
  let entry = num ? num : 0;
  return entry.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const addInfoColumn = (
  selectedStandardId,
  selectedStandardType,
  standards
) => {
  let selectedStandard = undefined;
  let dataGridColumns = [];

  if (selectedStandardId) {
    selectedStandard = standards.find((s) => s._id === selectedStandardId);
  }
  if (selectedStandard) {
    for (
      let infoIndex = 0;
      infoIndex < selectedStandard.infoTexts.length;
      infoIndex++
    ) {
      let property = 'info' + infoIndex;
      let infoTextItem = selectedStandard.infoTexts.find(
        (t) => t.infoProp === property
      );
      let infoText =
        infoTextItem && !infoTextItem.isHidden
          ? infoTextItem.infoStandardText
          : '';

      if (infoText) {
        dataGridColumns.push({
          field: '_info' + infoIndex,
          headerName: infoText,
          editable: false,
          width: 200,
          valueGetter: (params) =>
            getCertificateInfo(params, infoIndex, infoTextItem),
        });
      }
    }
  }

  if (selectedStandardType) {
    const myStandard = standards.find(
      (s) => s.standardType === selectedStandardType
    );
    for (
      let infoIndex = 0;
      infoIndex < myStandard.infoTexts.length;
      infoIndex++
    ) {
      let property = 'info' + infoIndex;
      let infoTextItem = myStandard.infoTexts.find(
        (t) => t.infoProp === property
      );
      let infoText =
        infoTextItem && !infoTextItem.isHidden
          ? infoTextItem.infoStandardText
          : '';

      if (infoText) {
        dataGridColumns.push({
          field: '_info' + infoIndex,
          headerName: infoText,
          editable: false,
          width: 200,
          valueGetter: (params) =>
            getCertificateInfo(params, infoIndex, infoTextItem),
        });
      }
    }
  }

  return dataGridColumns;
};

export const convertVolumeFromBlockchain = (
  initialUnit,
  quantity,
  targetUnit = enums.Units.KWH
) => {
  return initialUnit === enums.Units.MMBTU ||
    initialUnit === enums.Units.GRAMS ||
    initialUnit === enums.Units.VOYAGES ||
    initialUnit === enums.Units.TONS ||
    initialUnit === enums.Units.KG
    ? parseFloat(quantity)
    : parseFloat(convertUnits(quantity, 'Wh', targetUnit)); //We receive Wh from the blockchain
};

export const convertUnits = (quantity, fromUnit, toUnit) => {
  try {
    let result = math.unit(quantity, fromUnit).to(toUnit);

    return result.toNumber();
  } catch (error) {
    // Handle cases where the conversion isn't possible
    console.error('Conversion error: ', error.message);
    return null;
  }
};
