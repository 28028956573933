/* eslint-disable */

import { SnackbarCloseButton } from 'components/commons/Snackbar/SnackbarCloseButton';
import { SnackbarOpenButton } from 'components/commons/Snackbar/SnackbarOpenButton';

import { sleep } from 'utils/general/utils';
import { enums } from '../enums';
import { loadDataset } from './AdministrationActionsHelpers';
import {
  apiGet,
  apiPost,
  apiPut,
  fetchWithTimeout,
  openErrorSnack,
  openInfoSnack,
  openSuccessSnack,
} from './UtilsActions';

export const openActionDialog = (formToDisplayInActionDialog) => (dispatch) => {
  dispatch({
    type: 'OPEN_ACTION_DIALOG',
    payload: formToDisplayInActionDialog,
  });
};

export const closeActionDialog = (dialogIdentifier) => (dispatch) => {
  dispatch({ type: 'CLOSE_ACTION_DIALOG', payload: { dialogIdentifier } });
};

export const closeCheckAttributesForm = () => (dispatch) => {
  dispatch({ type: 'CLOSE_CHECK_ATTRIBUTES_FORM' });
};

export const closeJSONDiffCheckAttributesForm = () => (dispatch) => {
  dispatch({ type: 'CLOSE_JSON_DIFF_CHECK_ATTRIBUTES_FORM' });
};
export const closeBulkIssuanceForm = () => (dispatch) => {
  dispatch({ type: 'CLOSE_ISSUE_CERTIFICATES_BULK_FORM' });
};
export const closeIssuanceForm = () => (dispatch) => {
  dispatch({ type: 'CLOSE_ISSUE_CERTIFICATES_FORM' });
};

export const openIncreaseBalanceFormDialog = () => (dispatch) => {
  dispatch({ type: 'OPEN_INCREASE_BALANCE_FORM' });
};
export const openDecreaseBalanceFormDialog = () => (dispatch) => {
  dispatch({ type: 'OPEN_DECREASE_BALANCE_FORM' });
};
export const openIssueCertificateFormDialog = () => (dispatch) => {
  dispatch({ type: 'OPEN_ISSUE_CERTIFICATES_FORM' });
};

const sendMissingIssuerTransactions = async () => {
  let response = await apiPost('/api/transactions/set-missing-issuer', {});
  if (response.status >= 200 && response.status <= 299) {
    const transactionsUpdated = await response.json();
    return transactionsUpdated;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const fetchUpdateBalancesCache = async (socketId) => {
  apiPut('/api/administration/update-balances-cache', socketId);
};

export const setMissingIssuerTransactions = () => async (dispatch) => {
  dispatch({ type: 'SET_ISSUER_ON_TRANSACTIONS_START' });
  const transactionsUpdated = await sendMissingIssuerTransactions().catch(
    (error) => {
      //Catch generic error
      console.error(error);
      dispatch({ type: 'SET_ISSUER_ON_TRANSACTIONS_FAILS' });
      const errorMessage = error?.message;
      dispatch(openErrorSnack(errorMessage));
    }
  );

  if (transactionsUpdated && transactionsUpdated.error) {
    //Catch form error
    dispatch({ type: 'SET_ISSUER_ON_TRANSACTIONS_FAILS' });
    dispatch({
      type: 'INCREASE_BALANCE_FORM_ERROR',
      payload: transactionsUpdated.error,
    });
  } else if (transactionsUpdated) {
    //No error
    dispatch({ type: 'SET_ISSUER_ON_TRANSACTIONS_SUCCESS' });
    dispatch(openSuccessSnack('Transactions updated'));
  }
};

const getScheduledTasksMonitoring = async () => {
  let response = await apiGet('/api/scheduled-tasks-monitoring');
  if (response.status >= 200 && response.status <= 299) {
    const scheduledTasksMonitoring = await response.json();
    return scheduledTasksMonitoring;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const getScheduledTasksMonitoringDispatch = () => async (dispatch) => {
  let scheduledTasksMonitoring;
  try {
    scheduledTasksMonitoring = await getScheduledTasksMonitoring();
    scheduledTasksMonitoring &&
      dispatch({
        type: 'GET_SCHEDULED_TASKS_MONITORING_SUCCESS',
        payload: scheduledTasksMonitoring,
      });
  } catch (error) {
    console.error(error);
    dispatch(openErrorSnack(error?.message));
  }
  if (scheduledTasksMonitoring && scheduledTasksMonitoring.error) {
    dispatch(openErrorSnack(scheduledTasksMonitoring.error?.message));
  }
};

export const updateBalancesCache = () => async (dispatch) => {
  let socketId = localStorage.getItem('socketId');
  let fetchBody = {
    socketId: socketId,
  };
  dispatch(
    openInfoSnack('Start updated balances cache', {
      persist: true,
      action: SnackbarCloseButton,
    })
  );
  fetchUpdateBalancesCache(fetchBody);
};

export const closeIncreaseBalanceFormDialog = () => (dispatch) => {
  dispatch({ type: 'CLOSE_INCREASE_BALANCE_FORM' });
};
export const closeDecreaseBalanceFormDialog = () => (dispatch) => {
  dispatch({ type: 'CLOSE_DECREASE_BALANCE_FORM' });
};

export const displayIncreaseFormInDialog = () => (dispatch) => {
  dispatch({ type: 'DISPLAY_FORM_INCREASE_BALANCE' });
};
export const displayDecreaseFormInDialog = () => (dispatch) => {
  dispatch({ type: 'DISPLAY_FORM_DECREASE_BALANCE' });
};
export const displayIssueCertificatesFormInDialog = () => (dispatch) => {
  dispatch({ type: 'DISPLAY_FORM_ISSUE_CERTIFICATES' });
};
export const displayIssueCertificatesBulkFormInDialog = () => (dispatch) => {
  dispatch({ type: 'DISPLAY_FORM_ISSUE_CERTIFICATES_BULK' });
};
export const displayNotificationFutureIssuanceFormInDialog =
  () => (dispatch) => {
    dispatch({ type: 'DISPLAY_NOTIFICATION_OF_ISSUANCE_FORM' });
  };

export const displayCheckAttributesFormDialog = () => (dispatch) => {
  dispatch({ type: 'DISPLAY_FORM_CHECK_ATTRIBUTES' });
};

export const increaseForChanged = (_for) => (dispatch) => {
  dispatch({ type: 'INCREASE_FOR_CHANGED', payload: _for });
};
export const decreaseForChanged = (_for) => (dispatch) => {
  dispatch({ type: 'DECREASE_FOR_CHANGED', payload: _for });
};
export const issueOriginChanged = (origin) => (dispatch) => {
  dispatch({ type: 'ISSUE_ORIGIN_CHANGED', payload: origin });
};

export const increaseOriginChanged = (origin) => (dispatch) => {
  dispatch({ type: 'INCREASE_ORIGIN_CHANGED', payload: origin });
};
export const decreaseOriginChanged = (origin) => (dispatch) => {
  dispatch({ type: 'DECREASE_ORIGIN_CHANGED', payload: origin });
};

export const increaseVolumeChanged = (volume) => (dispatch) => {
  dispatch({ type: 'INCREASE_VOLUME_CHANGED', payload: volume });
};
export const decreaseVolumeChanged = (volume) => (dispatch) => {
  dispatch({ type: 'DECREASE_VOLUME_CHANGED', payload: volume });
};
export const issueVolumeChanged = (volume) => (dispatch) => {
  dispatch({ type: 'ISSUE_VOLUME_CHANGED', payload: volume });
};

export const increaseYearChanged = (year) => (dispatch) => {
  dispatch({ type: 'INCREASE_YEAR_CHANGED', payload: year });
};
export const decreaseYearChanged = (year) => (dispatch) => {
  dispatch({ type: 'DECREASE_YEAR_CHANGED', payload: year });
};
export const issueYearChanged = (year) => (dispatch) => {
  dispatch({ type: 'ISSUE_YEAR_CHANGED', payload: year });
};

export const increaseMonthChanged = (month) => (dispatch) => {
  dispatch({ type: 'INCREASE_MONTH_CHANGED', payload: month });
};
export const decreaseMonthChanged = (month) => (dispatch) => {
  dispatch({ type: 'DECREASE_MONTH_CHANGED', payload: month });
};
export const issueMonthChanged = (month) => (dispatch) => {
  dispatch({ type: 'ISSUE_MONTH_CHANGED', payload: month });
};

export const displayWarningInIncreaseDialog =
  (_increaseBalanceData) => (dispatch) => {
    dispatch({
      type: 'DISPLAY_WARNING_INCREASE_BALANCE',
      payload: _increaseBalanceData,
    });
  };
export const displayWarningInDecreaseDialog =
  (_decreaseBalanceData) => (dispatch) => {
    dispatch({
      type: 'DISPLAY_WARNING_DECREASE_BALANCE',
      payload: _decreaseBalanceData,
    });
  };
export const displayWarningInIssueDialog =
  (_issueCertificatesData) => (dispatch) => {
    dispatch({
      type: 'DISPLAY_WARNING_ISSUE_CERTIFICATES',
      payload: _issueCertificatesData,
    });
  };

export const getMaxQuantityToIssueForProductStart =
  (productMongoId, year, month) => async (dispatch) => {
    dispatch({ type: 'GET_MAX_QUANTITY_FOR_PRODUCT_START' });

    try {
      const quantityForProduct = await getQuantityForProduct(
        productMongoId,
        year,
        month
      );

      let availableQuantity = quantityForProduct?.availableQuantity || 0;

      dispatch({
        type: 'GET_MAX_QUANTITY_FOR_PRODUCT_SUCCESS',
        payload: availableQuantity,
      });
      return availableQuantity;
    } catch (error) {
      console.error(error);

      dispatch({ type: 'GET_MAX_QUANTITY_FOR_PRODUCT_FAIL' });
      return 0;
    }
  };

export const getQuantityForProduct = async (productMongoId, year, month) => {
  let response = await apiGet(
    `/api/transactions/available-quantity/${productMongoId}/${year}/${month}`
  );
  if (response.status >= 200 && response.status <= 299) {
    const result = await response.json();
    return result;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const displayWarningInBulkIssueDialog =
  (_issueCertificatesBulkData) => async (dispatch) => {
    dispatch({ type: 'LOADING_ISSUANCE_LIST', payload: true });
    let bulkIssuanceList = await getBulkIssuanceList(
      _issueCertificatesBulkData.organization._id,
      _issueCertificatesBulkData.year,
      _issueCertificatesBulkData.month
    );
    bulkIssuanceList = bulkIssuanceList.map((element) => {
      return {
        ...element,
        checked: false,
      };
    }); //set all elements as checked by default
    dispatch({ type: 'SET_BULK_ISSUANCE_LIST', payload: bulkIssuanceList });
    dispatch({ type: 'LOADING_ISSUANCE_LIST', payload: false });
    dispatch({
      type: 'DISPLAY_WARNING_ISSUE_CERTIFICATES_BULK',
      payload: _issueCertificatesBulkData,
    });
  };

export const displayWarningInNotificationOfFutureIssuanceDialog =
  (_notifyFutureIssuanceData) => async (dispatch) => {
    let notificationFutureIssuanceList = await getBulkIssuanceList(
      _notifyFutureIssuanceData.organization._id,
      _notifyFutureIssuanceData.year,
      _notifyFutureIssuanceData.month
    );
    notificationFutureIssuanceList = notificationFutureIssuanceList.map(
      (element) => {
        return { ...element, checked: true };
      }
    ); //set all elements as checked by default
    dispatch({
      type: 'SET_NOTIFICATION_FUTURE_ISSUANCE_LIST',
      payload: notificationFutureIssuanceList,
    });
    dispatch({
      type: 'DISPLAY_WARNING_NOTIFICATION_OF_ISSUANCE',
      payload: _notifyFutureIssuanceData,
    });
  };

export const setBulkIssuanceList = (issuanceList) => (dispatch) => {
  dispatch({ type: 'SET_BULK_ISSUANCE_LIST', payload: issuanceList });
};

const getBulkIssuanceList = async (organizationId, year, month) => {
  let response = await apiGet(
    `/api/transactions/products-with-available-quantity/${organizationId}/${year}/${month}`
  );
  if (response.status >= 200 && response.status <= 299) {
    const issueResult = await response.json();
    return issueResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const toggleDisplayAllFeatures = () => (dispatch) => {
  dispatch({ type: 'TOGGLE_DISPLAY_ALL_FEATURES' });
};

export const increaseTxHashChanged = (txHash) => (dispatch) => {
  dispatch({ type: 'INCREASE_TX_HASH_CHANGED', payload: txHash });
};
export const decreaseTxHashChanged = (txHash) => (dispatch) => {
  dispatch({ type: 'DECREASE_TX_HASH_CHANGED', payload: txHash });
};

export const increaseCommentChange = (comment) => (dispatch) => {
  dispatch({ type: 'INCREASE_COMMENT_CHANGED', payload: comment });
};
export const decreaseCommentChange = (comment) => (dispatch) => {
  dispatch({ type: 'DECREASE_COMMENT_CHANGED', payload: comment });
};

export const datasetChanged = (file) => (dispatch) => {
  dispatch({ type: 'DATASET_CHANGED', payload: file });
};

const increaseBalance = async (increaseBody) => {
  let response = await apiPut('/api/tokens/increase', increaseBody);
  if (response.status >= 200 && response.status <= 299) {
    const increaseResult = await response.json();
    return increaseResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const increaseBalanceStart =
  (_for, _origin, _year, _month, _volume, _comment, _txHash) =>
  async (dispatch) => {
    dispatch({ type: 'INCREASE_BALANCE_START' });
    const increaseBody = {
      _origin: _origin.assetID,
      _from: _for.identifier,
      _year: parseInt(_year, 10),
      _month: parseInt(_month, 10),
      _volume: _volume,
      _comment: _comment,
      _txHash: _txHash,
    };
    const increaseResult = await increaseBalance(increaseBody).catch(
      (error) => {
        //Catch generic error
        console.error(error);
        dispatch({ type: 'INCREASE_BALANCE_FAIL' });
        const errorMessage = error?.message;
        dispatch(openErrorSnack(errorMessage));
      }
    );

    if (increaseResult && increaseResult.error) {
      //Catch form error
      dispatch({ type: 'DISPLAY_FORM_INCREASE_BALANCE' });
      console.error('Catch form error', increaseResult.error);
      dispatch({ type: 'INCREASE_BALANCE_FAIL' });
      dispatch({
        type: 'INCREASE_BALANCE_FORM_ERROR',
        payload: increaseResult.error,
      });
    } else if (increaseResult) {
      //No error
      dispatch({ type: 'INCREASE_BALANCE_SUCCESS' });
      dispatch(openSuccessSnack('Balance increased'));
      dispatch({ type: 'CLOSE_INCREASE_BALANCE_FORM' });
      dispatch(resetFormIncreaseBalance());
    }
  };

export const uploadOperationsInBulk = async (operations) => {
  var formData = new FormData();

  let socketId = localStorage.getItem('socketId');
  formData.append('operations', JSON.stringify(operations));
  formData.append('socketId', socketId);

  const response = await fetch(
    process.env.REACT_APP_API_URL + '/api/manage/operations',
    {
      method: 'POST',
      credentials: 'include',
      body: formData,
    }
  );
  await sleep(12000); //Wait for the blockchain to process the transactions

  if (response.status >= 200 && response.status <= 299) {
    const result = await response.json();
    return result;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const issueCertificates = async (issueBody) => {
  const {
    isForcedIssue,
    _year,
    _month,
    _origin: productId,
    _volume,
    _unit: unit,
  } = issueBody;

  const month = parseInt(_month, 10) > 9 ? _month : '0' + _month;
  const year = parseInt(_year, 10);
  const period = `${year}-${month}`;
  const value = parseFloat(_volume, 10);
  let socketId = localStorage.getItem('socketId');

  const operations = [
    {
      type: 'issue',
      productId: productId,
      period: period,
      unit: unit,
      value: _volume,
      isForcedIssue,
    },
  ];

  const issueCertificatesResult = await uploadOperationsInBulk(operations);
  return issueCertificatesResult;
};

const issueCertificatesInBulk = async (issueBody) => {
  const { _productsWithProduction, _year, _month } = issueBody;
  const month = parseInt(_month, 10) > 9 ? _month : '0' + _month;
  const year = parseInt(_year, 10);
  const period = `${year}-${month}`;
  let socketId = localStorage.getItem('socketId');

  const operations = [];
  for (const productWithQuantityToIssue of _productsWithProduction) {
    const dataForIssueTransactionInJson = {
      type: 'issue',
      productId: productWithQuantityToIssue.product.productId,
      period: period,
      unit: productWithQuantityToIssue.product.unit,
      value: productWithQuantityToIssue.quantityToIssue,
    };
    operations.push(dataForIssueTransactionInJson);
  }

  const issueCertificatesInBulk = await uploadOperationsInBulk(operations);
  return issueCertificatesInBulk;
};

const notifyFutureIssue = async (notifyBody) => {
  let response = await apiPost('/api/certificates/notify', notifyBody);
  if (response.status >= 200 && response.status <= 299) {
    const notifyResult = await response.json();
    return notifyResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

const checkProductAttributesInBlockchain = async (_origin) => {
  let response = await apiGet(`/api/fields/all/${_origin}`);
  if (response.status >= 200 && response.status <= 299) {
    const checkResult = await response.json();
    return checkResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const forceIssueSnapshotCreation = (forceIssue) => (dispatch) => {
  dispatch({ type: 'FORCE_ISSUE_SNAPSHOT_CREATION', payload: forceIssue });
};

export const issueCertificatesStart =
  (_origin, _year, _month, _volume, isForcedIssue) => async (dispatch) => {
    dispatch({ type: 'ISSUE_CERTIFICATES_START' });
    const issueBody = {
      _origin: _origin.assetID,
      _unit: _origin.unit,
      _year: parseInt(_year, 10),
      _month: parseInt(_month, 10),
      _volume: _volume,
      isForcedIssue: isForcedIssue,
    };
    const issueResult = await issueCertificates(issueBody).catch((error) => {
      //Catch generic error
      console.error(error);
      dispatch({ type: 'ISSUE_CERTIFICATES_FAIL' });
      const errorMessage = error?.message;
      dispatch(openErrorSnack(errorMessage));
    });

    if (issueResult && issueResult.error) {
      //Catch form error
      dispatch({ type: 'DISPLAY_FORM_ISSUE_CERTIFICATES' });
      console.error('Catch form error', issueResult.error);
      dispatch({ type: 'ISSUE_CERTIFICATES_FAIL' });
      dispatch({
        type: 'ISSUE_CERTIFICATES_FORM_ERROR',
        payload: issueResult.error,
      });
    } else if (issueResult) {
      //No error

      dispatch(
        openInfoSnack('', {
          persist: true,
          action: (
            <SnackbarOpenButton
              path={`/operations/feed/${issueResult.operationId}`}
              text={'Processing issue certificates... Click to open details'}
            />
          ),
        })
      );

      dispatch({ type: 'ISSUE_CERTIFICATES_SUCCESS' });
      dispatch({ type: 'CLOSE_ISSUE_CERTIFICATES_FORM' });
      dispatch(resetFormIssueCerticates());
    }
  };
export const issueCertificatesInBulkStart =
  (bulkIssuanceList, _year, _month) => async (dispatch) => {
    dispatch({ type: 'ISSUE_CERTIFICATES_BULK_START' });
    let socketId = localStorage.getItem('socketId');
    let checkedProductsWithProductionList = bulkIssuanceList.filter(
      (element) => element.checked
    );

    //We issue all the available volumes for the selected products
    //So we take the availableQuantity for each product and set them as quantityToIssue
    checkedProductsWithProductionList = checkedProductsWithProductionList.map(
      (element) => {
        const quantityToIssue = element.availableQuantity;
        delete element.availableQuantity;
        return {
          ...element,
          quantityToIssue,
        };
      }
    );

    const issueBody = {
      socketId: socketId,
      _productsWithProduction: checkedProductsWithProductionList,
      _year: parseInt(_year, 10),
      _month: parseInt(_month, 10),
    };

    const issueResult = await issueCertificatesInBulk(issueBody).catch(
      (error) => {
        //Catch generic error
        console.error(error);
        dispatch({ type: 'ISSUE_CERTIFICATES_BULK_FAIL' });
      }
    );

    if (issueResult && issueResult.error) {
      //Catch form error
      dispatch({ type: 'DISPLAY_FORM_ISSUE_CERTIFICATES_BULK' });
      console.error('Catch form error', issueResult.error);
      dispatch({ type: 'ISSUE_CERTIFICATES_BULK_FAIL' });
      dispatch({
        type: 'ISSUE_CERTIFICATES_BULK_FORM_ERROR',
        payload: issueResult.error,
      });
    } else if (issueResult) {
      //No error
      dispatch(
        openInfoSnack('', {
          persist: true,
          action: (
            <SnackbarOpenButton
              path={`/operations/feed/${issueResult.operationId}`}
              text={'Processing issue in bulk... Click to open details'}
            />
          ),
        })
      );
      dispatch({ type: 'CLOSE_ISSUE_CERTIFICATES_BULK_FORM' });
      dispatch(resetFormIssueCerticatesInBulk());
    }
  };

export const closeNotificationOfIssuanceForm = () => (dispatch) => {
  dispatch({ type: 'CLOSE_NOTIFICATION_OF_ISSUANCE_FORM' });
};

export const notifyFutureIssueStart =
  (notificationFutureIssuanceList, _year, _month, _organization) =>
  async (dispatch) => {
    dispatch({ type: 'NOTIFICATION_OF_ISSUANCE_START' });
    let socketId = localStorage.getItem('socketId');
    let checkedProductsWithProductionList =
      notificationFutureIssuanceList.filter((element) => element.checked);

    const notifyBody = {
      socketId: socketId,
      _productsWithProduction: checkedProductsWithProductionList,
      _year: parseInt(_year, 10),
      _month: parseInt(_month, 10),
      _organization: _organization,
    };

    let notifyResult;
    try {
      notifyResult = await notifyFutureIssue(notifyBody);
    } catch (error) {
      //Catch generic error
      console.error('notifyFutureIssueStart error', error);
      dispatch({ type: 'NOTIFICATION_OF_ISSUANCE_FAIL' });
    }

    if (notifyResult && notifyResult.error) {
      //Catch form error
      dispatch({ type: 'DISPLAY_NOTIFICATION_OF_ISSUANCE_FORM' });
      console.error('Catch form error', notifyResult.error);
      dispatch({ type: 'NOTIFICATION_OF_ISSUANCE_FAIL' });
      dispatch({
        type: 'NOTIFICATION_OF_ISSUANCE_FORM_ERROR',
        payload: notifyResult.error,
      });
    } else if (notifyResult) {
      //No error
      dispatch({ type: 'NOTIFICATION_OF_ISSUANCE_SUCCESS' });
      dispatch({ type: 'CLOSE_NOTIFICATION_OF_ISSUANCE_FORM' });
      dispatch(resetFormIssuanceEmailNotification());
    }
  };

export const checkAttributesStart = (_origin) => async (dispatch) => {
  dispatch({ type: 'RESET_CHECK_ATTRIBUTES_RESULT' });
  dispatch({ type: 'UPDATE_CHECK_ATTRIBUTES_DATA', payload: _origin });
  dispatch({ type: 'CHECK_ATTRIBUTES_START' });

  const origin = _origin.assetID;
  let checkResult;

  try {
    checkResult = await checkProductAttributesInBlockchain(origin);
  } catch (error) {
    //Catch generic error
    console.error(error);
    dispatch({ type: 'CHECK_ATTRIBUTES_FAIL' });
    const errorMessage = error?.message;
    dispatch(openErrorSnack(errorMessage));
  }
  if (checkResult && checkResult.error) {
    //Catch form error
    dispatch({ type: 'DISPLAY_FORM_CHECK_ATTRIBUTES' });
    console.error('Catch form error', issueResult.error);
    dispatch({ type: 'CHECK_ATTRIBUTES_FAIL' });
    dispatch({
      type: 'CHECK_ATTRIBUTES_FORM_ERROR',
      payload: issueResult.error,
    });
  } else if (checkResult) {
    //No error
    dispatch({ type: 'CHECK_ATTRIBUTES_SUCCESS' });
    dispatch({ type: 'SET_CHECK_ATTRIBUTES_RESULT', payload: checkResult });
    dispatch(openSuccessSnack('Verification complete'));
    dispatch(resetFormCheckProductsAttributes());
  }
};

const decreaseBalance = async (decreaseBody) => {
  let response = await apiPut('/api/tokens/decrease', decreaseBody);
  if (response.status >= 200 && response.status <= 299) {
    const decreaseResult = await response.json();
    return decreaseResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const resetFormDecreaseBalance = () => (dispatch) => {
  dispatch({ type: 'RESET_DECREASE_BALANCE_FORM' });
};
export const resetFormIncreaseBalance = () => (dispatch) => {
  dispatch({ type: 'RESET_INCREASE_BALANCE_FORM' });
};
export const resetFormIssuanceEmailNotification = () => (dispatch) => {
  dispatch({ type: 'RESET_ISSUANCE_EMAIL_NOTIFICATION_FORM' });
};
export const resetFormIssueCerticates = () => (dispatch) => {
  dispatch({ type: 'RESET_CERTIFICATES_FORM' });
};
export const resetFormIssueCerticatesInBulk = () => (dispatch) => {
  dispatch({ type: 'RESET_CERTIFICATES_IN_BULK_FORM' });
};
export const resetFormCheckProductsAttributes = () => (dispatch) => {
  dispatch({ type: 'RESET_CHECK_PRODUCT_ATTRIBUTES_FORM' });
};

export const decreaseBalanceStart =
  (_for, _origin, _year, _month, _volume, _comment, _txHash) =>
  async (dispatch) => {
    dispatch({ type: 'DECREASE_BALANCE_START' });

    const decreaseBody = {
      _origin: _origin.assetID,
      _from: _for.identifier,
      _year: parseInt(_year, 10),
      _month: parseInt(_month, 10),
      _volume: _volume,
      _comment: _comment,
      _txHash: _txHash,
    };

    const decreaseResult = await decreaseBalance(decreaseBody).catch(
      (error) => {
        //Catch generic error
        console.error(error);
        dispatch({ type: 'DECREASE_BALANCE_FAIL' });
        const errorMessage = error?.message;
        dispatch(openErrorSnack(errorMessage));
      }
    );

    if (decreaseResult && decreaseResult.error) {
      //Catch form error
      dispatch({ type: 'DISPLAY_FORM_DECREASE_BALANCE' });
      console.error('Catch form error', decreaseResult.error);
      dispatch({ type: 'DECREASE_BALANCE_FAIL' });
      dispatch({
        type: 'DECREASE_BALANCE_FORM_ERROR',
        payload: decreaseResult.error,
      });
    } else if (decreaseResult) {
      //No error
      dispatch({ type: 'DECREASE_BALANCE_SUCCESS' });
      dispatch(openSuccessSnack('balance decreased'));
      dispatch({ type: 'CLOSE_DECREASE_BALANCE_FORM' });
      dispatch(resetFormDecreaseBalance());
    }
  };

export const confirmationSourceDocumentChanged = (file) => (dispatch) => {
  dispatch({ type: 'CONFIRMATION_SOURCE_DOCUMENT_CHANGED', payload: file });
};

export const sourceDocumentChanged = (file) => (dispatch) => {
  dispatch({ type: 'SOURCE_DOCUMENT_CHANGED', payload: file });
};

export const confirmationDatasetChanged = (file) => (dispatch) => {
  dispatch({ type: 'CONFIRMATION_DATASET_CHANGED', payload: file });
};

export const bulkOperationsDatasetChanged = (file) => (dispatch) => {
  dispatch({ type: 'BULK_OPERATIONS__DATASET_CHANGED', payload: file });
};

export async function getDataHashAndFilenameForSourceFile(
  sourceDocumentFile,
  dispatch
) {
  return new Promise((resolve, reject) => {
    dispatch({ type: 'UPLOAD_CONFIRMATION_SOURCE_START' });
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.error('file reading has failed');
    reader.onload = async () => {
      try {
        let uploadConfirmationSourceResult = await uploadConfirmationSource(
          sourceDocumentFile
        );

        dispatch({ type: 'UPLOAD_CONFIRMATION_SOURCE_SUCCESS' });
        dispatch(openSuccessSnack('Source document uploaded'));
        resolve(uploadConfirmationSourceResult);
      } catch (error) {
        const errorMessage = error.message
          ? 'Source file upload failed : ' + error.message
          : 'Source file  upload failed';

        dispatch(openErrorSnack(errorMessage));
      }
    };
    reader.readAsArrayBuffer(sourceDocumentFile);
  });
}

async function readAndUploadConfirmationMultipleDataset(
  datasetFile,
  sourceDocumentHash,
  sourceDocumentFileName,
  dispatch
) {
  return new Promise((resolve, reject) => {
    dispatch({ type: 'UPLOAD_CONFIRMATION_DATASET_START' });
    try {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.error('file reading has failed');
      reader.onload = async () => {
        try {
          let uploadConfirmationDatasetResult =
            await uploadConfirmationMultipleDataset(
              datasetFile,
              sourceDocumentHash,
              sourceDocumentFileName
            );

          dispatch({ type: 'UPLOAD_CONFIRMATION_DATASET_SUCCESS' });
          dispatch(
            openInfoSnack('', {
              persist: true,
              action: (
                <SnackbarOpenButton
                  path={`/operations/feed/${uploadConfirmationDatasetResult.operationId}`}
                  text={'Processing multi upload... Click to open details'}
                />
              ),
            })
          );
          resolve(uploadConfirmationDatasetResult);
          dispatch(confirmationSourceDocumentChanged(null));
          dispatch(confirmationDatasetChanged(null));
        } catch (error) {
          dispatch({ type: 'UPLOAD_CONFIRMATION_DATASET_FAIL' });
          const errorMessage = error.message
            ? 'Dataset upload failed : ' + error.message
            : 'Dataset upload failed';

          dispatch(openErrorSnack(errorMessage));
        }
      };
      reader.readAsArrayBuffer(datasetFile);
    } catch (error) {
      dispatch({ type: 'UPLOAD_CONFIRMATION_DATASET_FAIL' });
      console.error('Confirmation dataset failed with error', error);
      dispatch(openErrorSnack('Dataset upload failed'));
    }
  });
}
export const readAndUploadBulkOperationsDataset =
  (datasetFile) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: 'UPLOAD_BULK_OPERATIONS_DATASET_START' });
      try {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.error('file reading has failed');
        reader.onload = async () => {
          await loadDataset({
            datasetFile,
            uploadFunction: uploadBulkOperationsDataset,
            datasetIdentifier: 'BULK_OPERATIONS_DATASET',
            dispatch,
            resolve,
          });
        };
        reader.readAsArrayBuffer(datasetFile);
        dispatch(bulkOperationsDatasetChanged(null));
      } catch (error) {
        console.error('Bulk Operations dataset failed with error', error);
        dispatch({ type: `UPLOAD_BULK_OPERATIONS_DATASET_FAIL` });
        dispatch(openErrorSnack('Dataset upload failed'));
      }
    });
  };

export const uploadConfirmationMultipleDatasetAndSource =
  (sourceDocumentAndDataset) => async (dispatch) => {
    let sourceDocumentFile = sourceDocumentAndDataset.sourceDocument;
    let datasetFile = sourceDocumentAndDataset.dataset;
    let dataHashAndFilename = {
      hash: '',
      fileName: '',
    };
    if (sourceDocumentFile) {
      //Upload the source document and get dataHash
      dataHashAndFilename = await getDataHashAndFilenameForSourceFile(
        sourceDocumentFile,
        dispatch
      );
    }
    //Upload the dataset and attach the datahash
    try {
      await readAndUploadConfirmationMultipleDataset(
        datasetFile,
        dataHashAndFilename.hash,
        dataHashAndFilename.fileName,
        dispatch
      );
    } catch (error) {
      console.log('error in upload Multiple Dataset And Source');
    }
  };

export const uploadConfirmationMultipleDataset = async (
  file,
  sourceDocumentHash,
  sourceDocumentFileName
) => {
  var formData = new FormData();
  formData.append('dataset', file);
  formData.append('sourceDocumentHash', sourceDocumentHash);
  formData.append('sourceDocumentFileName', sourceDocumentFileName);
  let socketId = localStorage.getItem('socketId');
  formData.append('socketId', socketId);

  const response = await fetch(
    process.env.REACT_APP_API_URL + '/api/manage/data-upload',
    {
      method: 'POST',
      credentials: 'include',
      body: formData,
    }
  );

  if (response.status >= 200 && response.status <= 299) {
    const uploadResult = await response.json();
    return uploadResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const uploadBulkOperationsDataset = async (file) => {
  let socketId = localStorage.getItem('socketId');
  var formData = new FormData();
  formData.append('operations', file);
  formData.append('socketId', socketId);

  const response = await fetchWithTimeout(
    process.env.REACT_APP_API_URL + '/api/manage/operations',
    {
      method: 'POST',
      credentials: 'include',
      body: formData,
      timeout: 1200000,
    }
  );
  if (response.status >= 200 && response.status <= 299) {
    const uploadResult = await response.json();
    return uploadResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const uploadSourceAndData =
  (sourceDocumentAndDataset) => async (dispatch) => {
    let sourceDocumentFile = sourceDocumentAndDataset.sourceDocument;
    let dataset = sourceDocumentAndDataset.dataset;

    let dataHashAndFilename = {
      hash: '',
      filename: '',
    };
    if (sourceDocumentFile) {
      //Upload the source document and get dataHash
      dataHashAndFilename = await getDataHashAndFilenameForSourceFile(
        sourceDocumentFile,
        dispatch
      );
    }

    //Upload the dataset and attach the datahash
    dispatch({ type: 'UPLOAD_DATASET_START' });
    try {
      const result = await uploadDataset(
        dataset,
        dataHashAndFilename.hash,
        dataHashAndFilename.fileName,
        dispatch
      );

      dispatch({ type: 'UPLOAD_DATASET_SUCCESS' });
      dispatch(
        openInfoSnack('', {
          persist: true,
          action: (
            <SnackbarOpenButton
              path={`/operations/feed/${result.operationId}`}
              text={'Processing upload... Click to open details'}
            />
          ),
        })
      );
      dispatch(sourceDocumentChanged(null));
    } catch (error) {
      const errorMessage = error?.message
        ? 'Dataset upload failed : ' + error.message
        : 'Dataset upload failed';

      dispatch({ type: 'UPLOAD_DATASET_SUCCESS' });
      dispatch(openErrorSnack(errorMessage));
    }
  };

export const uploadDataset = async (
  values,
  sourceDocumentHash,
  sourceDocumentFileName
) => {
  values.socketId = localStorage.getItem('socketId');

  let measure = values.value;
  let isIncrementalFlag = false;
  if (
    (values.type === 'quantity' || values.type === 'volume') &&
    values?.addedVolume > 0
  ) {
    measure = values.addedVolume;
    isIncrementalFlag = true;
  }
  if (values.type !== 'quantity' && values.type !== 'volume') {
    //dynamicData use cas, we must use the value of the dynamic data
    //Let's find the related standard data
    const { unit, sourceName } = values.origin?.standard?.infoTexts?.find(
      (element) =>
        element.type === 'dynamicData' && element.sourceName === values.type
    );
    values.unit = unit;
    values.type = 'dynamic_data';
    values.productSource = sourceName;
  } else {
    values.productSource = values.origin.source;
    values.unit = values.origin.unit;
  }

  const dataset = [
    {
      asset_id: values.origin.assetID,
      period: `${values.year}-${values.month}`,
      unit: values.unit,
      measure,
      productSource: values.productSource,
      type: values.type,
      isIncrementalFlag,
    },
  ];

  let response = await apiPost('/api/manage/data-upload', {
    dataset,
    sourceDocumentHash,
    sourceDocumentFileName,
    socketId: values.socketId,
  });
  if (response.status >= 200 && response.status <= 299) {
    const result = await response.json();
    return result;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const uploadConfirmationSource = async (file) => {
  var formData = new FormData();
  formData.append('files', file);

  const sourceDataHashAndFilename = await fetch(
    process.env.REACT_APP_API_URL + '/api/manage/upload-confirmation-source',
    {
      method: 'POST',
      credentials: 'include',
      body: formData,
    }
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (body) {
      return body;
    });
  return sourceDataHashAndFilename;
};
