/* eslint-disable */
import React, { useEffect } from 'react';
import { useTheme, css, ClassNames } from '@emotion/react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Card from '@mui/material/Card';
import { CardHeader, CardContent } from '@mui/material';
import BackButton from '../commons/BackButton/BackButton';

import CreateIcon from '@mui/icons-material/Create';

import userIcon from '../../assets/images/icons/person.png';
import organizationIcon from '../../assets/images/icons/company.png';
import Grid from '@mui/material/Grid';
import { withRouter } from '../../utils/migrationHelper';
import { withTranslation } from 'react-i18next';
import { capitalize } from '../../utils/text';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import OrganizationProductsListComponent from './OrganizationProductsListComponent';
import OrganizationUsersListComponent from './OrganizationUsersListComponent';

import {
  getOrganizationStart,
  getOrganizationMailingListsStart,
  getOrganizationUsers,
  getOrganizationProducts,
} from '../../actions';
import { checkUserIsSuperAdmin } from '../../utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';
import { RightReferences } from 'enums/RightReferences';
import { CrudPermissions } from 'enums/CrudPermissions';
import { useOrganizationLogo } from 'components/Drawer/utils/logo';

let Organization = (props) => {
  const {
    isAuthenticated,
    user,
    getOrganizationStart,
    getOrganizationMailingListsStart,
    currentOrganization,
    loading,
    mailingLists,
    getOrganizationProducts,
    organizationProducts,
    getOrganizationUsers,
    organizationUsers,
    localOrganizationPicture,
  } = props;

  let navigate = useNavigate();
  let params = useParams();
  const classes = useTheme();
  const { organizationPicture } = useOrganizationLogo(
    currentOrganization,
    localOrganizationPicture
  );

  useEffect(() => {
    async function initComponent() {
      if (isAuthenticated) {
        await getOrganizationStart(params.organizationId, navigate);
        let isSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);
        if (isSuperAdmin) {
          await getOrganizationMailingListsStart(params.organizationId);
          getOrganizationProducts(params.organizationId);
          getOrganizationUsers(params.organizationId);
        }

        let userOrganization = user?.user_metadata?.organization;
        let userOrganizationId = userOrganization._id;

        if (
          checkUserHasRight(isAuthenticated, user, RightReferences.USERS, [
            CrudPermissions.VIEW,
          ]) &&
          userOrganizationId === params.organizationId
        ) {
          getOrganizationUsers(params.organizationId);
        }

        if (
          checkUserHasRight(isAuthenticated, user, RightReferences.PRODUCTS, [
            CrudPermissions.VIEW,
          ]) &&
          userOrganizationId === params.organizationId
        ) {
          getOrganizationProducts(params.organizationId);
        }
      } else {
        navigate('/');
      }
    }
    initComponent();
  }, []);

  const renderUpdateButton = () => {
    let canUpdateOrganization = false;
    if (
      checkUserHasRight(isAuthenticated, user, RightReferences.ORGANIZATIONS, [
        CrudPermissions.UPDATE,
      ]) ||
      checkUserIsSuperAdmin(isAuthenticated, user)
    )
      canUpdateOrganization = true;

    if (canUpdateOrganization) {
      return (
        <Fab
          color="primary"
          aria-label="add"
          style={{
            margin: 0,
            bottom: 'auto',
            right: 48,
            top: 85,
            left: 'auto',
            position: 'fixed',
          }}
          onClick={() => {
            navigate('/organizations/update/' + currentOrganization._id);
          }}
        >
          <CreateIcon />
        </Fab>
      );
    } else return false;
  };

  if (loading) {
    return (
      <ClassNames>
        {({ css }) => (
          <div className={css(classes.root)}>
            <CircularProgress
              size={24}
              className={css(classes.buttonProgress)}
            />
          </div>
        )}
      </ClassNames>
    );
  }

  if (!currentOrganization || !currentOrganization._id) {
    return (
      <ClassNames>
        {({ css }) => <div className={css(classes.root)}>Error</div>}
      </ClassNames>
    );
  }

  let isSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);

  //Connected user can access the users of this organization
  //if he has the right VIEW USERS
  // and if the organization is one of its business organization
  let userOrganization = user?.user_metadata?.organization;
  let userOrganizationId = userOrganization._id;
  let userBusinessOrganizations = userOrganization?.businessOrganizations;
  let userBusinessOrganizationIds = userBusinessOrganizations
    ? userBusinessOrganizations.map((o) => o._id)
    : [];

  let canAccessOrganizationUsers = false;
  let canAccessOrganizationProducts = false;

  if (isSuperAdmin) {
    canAccessOrganizationUsers = true;
  } else if (
    checkUserHasRight(isAuthenticated, user, RightReferences.USERS, [
      CrudPermissions.VIEW,
    ]) &&
    userOrganizationId === currentOrganization._id
  ) {
    canAccessOrganizationUsers = true;
  }

  if (isSuperAdmin) {
    canAccessOrganizationProducts = true;
  } else if (
    checkUserHasRight(isAuthenticated, user, RightReferences.PRODUCTS, [
      CrudPermissions.VIEW,
    ]) &&
    userOrganizationId === currentOrganization._id
  ) {
    canAccessOrganizationProducts = true;
  }

  let numberOfItems = 0;
  if (canAccessOrganizationUsers) numberOfItems++;
  if (canAccessOrganizationProducts) numberOfItems++;

  return (
    <>
      <BackButton />
      {renderUpdateButton()}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <img src={organizationPicture} style={{ maxHeight: 20 }} />
              }
              title={currentOrganization.name}
              subheader={`identifier: ${currentOrganization.identifier}`}
            />
            <CardContent>
              <div className={css(classes.wrapper)}>
                <List dense>
                  <ListItem>
                    {currentOrganization?.lei && (
                      <ListItemText
                        primary={'LEI'}
                        secondary={currentOrganization?.lei}
                      />
                    )}
                    <ListItemText
                      primary={'Contact name'}
                      secondary={
                        capitalize(currentOrganization.contactFirstName) +
                        ' ' +
                        capitalize(currentOrganization.contactLastName)
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={'Contact email'}
                      secondary={currentOrganization.contactEmail}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={'Contact phone number'}
                      secondary={currentOrganization.contactPhoneNumber}
                    />
                  </ListItem>
                  {currentOrganization.address && (
                    <ListItem>
                      <ListItemText
                        primary={'Address'}
                        secondary={currentOrganization.address}
                      />
                    </ListItem>
                  )}
                </List>
              </div>
            </CardContent>
          </Card>
        </Grid>

        {canAccessOrganizationProducts && (
          <Grid item xs={12} md={6}>
            <OrganizationProductsListComponent
              isSuperAdmin={isSuperAdmin}
              organizationProducts={organizationProducts}
              loading={loading}
            />
          </Grid>
        )}

        {canAccessOrganizationUsers && (
          <Grid item xs={12} md={6}>
            <OrganizationUsersListComponent
              organizationUsers={organizationUsers}
              loading={loading}
            />
          </Grid>
        )}

        {isSuperAdmin && (
          <Grid item xs={12}>
            <Card>
              <CardHeader
                avatar={<img src={userIcon} style={{ maxHeight: 20 }} />}
                title={'Admin data'}
              />
              <CardContent>
                <div className={css(classes.wrapper)}>
                  <List dense>
                    <ListItem>
                      <ListItemText
                        primary={'Business Organizations'}
                        secondary={
                          currentOrganization.businessOrganizations &&
                          currentOrganization.businessOrganizations?.length > 0
                            ? currentOrganization.businessOrganizations.map(
                                (org, index) => {
                                  let spacer = ', ';
                                  if (
                                    index ===
                                    currentOrganization.businessOrganizations
                                      ?.length -
                                      1
                                  )
                                    spacer = '';
                                  return org.name + spacer;
                                }
                              )
                            : 'No business organizations'
                        }
                      />
                    </ListItem>
                    {mailingLists &&
                      mailingLists.map((mailingList) => {
                        let title = mailingList.transactionType;
                        if (mailingList.from)
                          title += ' from ' + mailingList.from?.name;
                        if (mailingList.to)
                          title += ' to ' + mailingList.to?.name;
                        return (
                          <ListItem key={mailingList._id}>
                            <ListItemText
                              key={mailingList.type}
                              primary={title}
                              secondary={
                                mailingList.emails &&
                                mailingList.emails?.length > 0
                                  ? mailingList.emails.map((email, index) => {
                                      let spacer = ', ';
                                      if (
                                        index ===
                                        mailingList.emails?.length - 1
                                      )
                                        spacer = '';
                                      return email + spacer;
                                    })
                                  : 'No mail in this mailing list'
                              }
                            />
                          </ListItem>
                        );
                      })}
                  </List>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = ({ organizationsReducer, loginReducer }) => {
  const {
    currentOrganization,
    loading,
    mailingLists,
    loadingMailingLists,
    organizationProducts,
    organizationUsers,
    localOrganizationPicture,
  } = organizationsReducer;
  let activeProducts = organizationProducts.filter((product) => product.active);
  const { isAuthenticated, user } = loginReducer;
  return {
    currentOrganization,
    localOrganizationPicture,
    loading,
    isAuthenticated,
    user,
    mailingLists,
    loadingMailingLists,
    organizationProducts: activeProducts,
    organizationUsers,
  };
};

const mapDispatchToProps = {
  getOrganizationStart,
  getOrganizationMailingListsStart,
  getOrganizationUsers,
  getOrganizationProducts,
};

Organization = withTranslation('translations')(Organization);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Organization)
);
