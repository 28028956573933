import { archiveProductStart, restoreProductStart } from 'actions';
import ProductLoader from 'components/Loader/ProductLoader';
import ProductReferenceFilesLoader from 'components/Loader/ProductReferenceFilesLoader';
import ProductFormButtons from 'components/commons/ProductionChart/ProductFormButtons';
import GasProductFormCard from 'components/Product/Form/GasProductForm/GasProductFormCard';
import DataAcquisitionContext from 'components/ProductDataAcquisition/Context/DataAcquisitonContext';
import ProductDataAcquisitionCard from 'components/ProductDataAcquisition/ProductDataAcquisitionsCard';
import ProductReferenceFiles from 'components/ReferenceFiles/ProductReferenceFiles';
import { Formik } from 'formik';
import { withCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import { withRouter } from 'utils/migrationHelper';

import AddDataProductDataDialog from './CustomComponents/Dialog/AddProductData/AddDataProductDataDialog';
import OrganizationPopupMessage from './CustomComponents/Popup/OrganizationPopupMessage/OrganizationPopupMessage';
import useCreateOrUpdateProduct from './Redux/CustomHooks/useCreateOrUpdateProduct';
import useLoadingResources from './Redux/CustomHooks/useLoadingResources';
import useCreateOrUpdateProductSlicesCombiner from './Redux/Slices/useCreateOrUpdateProductSlicesCombiner';
import {
  displayPlatinumUI,
  handleOnSubmit,
  handleOnValidate,
  processFormInitialValues,
} from './utils/utils';

const CreateOrUpdateProduct = (props: any): JSX.Element => {
  const { cookies } = props;
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { assetId } = useParams();
  const {
    loading,
    currentProduct,
    organizations,
    standards,
    localProductPicture,
    selectedWells,
    localProductReferenceFiles,
    loadingReferenceFilesData,
  } = useCreateOrUpdateProductSlicesCombiner();
  const { sourceHistory, addSourceHistoryItem, removeSourceHistoryItem } =
    useCreateOrUpdateProduct();

  const {
    getLoadingCondition,
    getLoadingReferenceFileCondition,
    getNoOrganizationsCondition,
    getNoProductTypesCondition,
    getNoStandardCondition,
  } = useLoadingResources();

  if (getLoadingReferenceFileCondition())
    return (
      <ProductReferenceFilesLoader
        loadingReferenceFilesData={loadingReferenceFilesData}
      />
    );
  if (getLoadingCondition()) return <ProductLoader />;
  if (
    getNoOrganizationsCondition() ||
    getNoProductTypesCondition() ||
    getNoStandardCondition()
  )
    return <OrganizationPopupMessage />;

  return (
    <>
      <Formik
        initialValues={processFormInitialValues({
          currentProduct,
          organizations,
          standards,
        })}
        onSubmit={async (values: any) =>
          handleOnSubmit({
            organizations,
            values,
            standards,
            currentProduct,
            localProductReferenceFiles,
            selectedWells,
            localProductPicture,
            navigate,
            sourceHistory,
            assetId,
            dispatch,
          })
        }
        validate={(values: any) => handleOnValidate({ standards, values })}
      >
        {({ submitForm }) => {
          return (
            <>
              <input type="hidden" name="_csrf" value={cookies.cookies._csrf} />
              <GasProductFormCard data-testid="gas-product-form-card-id" />
              <ProductReferenceFiles
                userCanEditReferenceFiles={true}
                userCanReadReferenceFiles={true}
              />
              <br />
              {(!displayPlatinumUI || (displayPlatinumUI && assetId)) && (
                <DataAcquisitionContext.Provider
                  value={{
                    addSourceHistoryItem,
                    removeSourceHistoryItem,
                    sourceHistory,
                  }}
                >
                  <ProductDataAcquisitionCard />
                </DataAcquisitionContext.Provider>
              )}
              <ProductFormButtons
                data-testid="product-details-buttons-id"
                currentProduct={currentProduct}
                loading={loading}
                submitForm={submitForm}
                assetId={assetId}
                restoreProductStart={restoreProductStart}
                archiveProductStart={archiveProductStart}
              />
            </>
          );
        }}
      </Formik>
      <AddDataProductDataDialog />
    </>
  );
};

export default withRouter(withCookies(CreateOrUpdateProduct));
