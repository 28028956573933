/* eslint-disable max-lines */
import { Button, CircularProgress, Fab, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import useCertificesSlicesCombiner from 'components/Certificates/Redux/Slices/useCertificatesSlicesCombiner';
import DateCell from 'components/commons/DateCell';
import LinkOpenProductDialog from 'components/commons/LinkOpenProductDialog/LinkOpenProductDialog';
import { displayPlatinumUI } from 'components/CreateOrUpdateProduct/utils/utils';
import {
  periodTitle,
  quantityTitle,
} from 'components/Transactions/CustomComponents/Tables/utils/constants';
import { enums } from 'enums';
import { RightReferences } from 'enums/RightReferences';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { convertGramsInOz } from 'utils/convert';
import {
  certificateQuantityRenderer,
  getCertificateClientName,
  getCertificateDateFromMonthAndYearText,
  getCertificateOriginName,
  getCertificateOriginUnit,
  getCertificateProductId,
  getCertificateQuantity,
  monthYearComparator,
} from 'utils/datagridHelpers';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

import certificateIcon from '../../../../../assets/images/icons/standard.png';
import trashIcon from '../../../../../assets/images/icons/trash.png';
import CertificateTableCell from '../CustomCell/CertificateTableCell';

import getDynamicColumns from './DynamicColumns';
import {
  handleGenerateCertificatePdf,
  handleOpenCancelCertificateFormDialog,
  handleOpenSecretWordDialog,
} from './utils';
import TransactionAndSecretOptions from '../Options/TransactionAndSecretOptions';

const CertificatesColumns = (): GridColDef[] => {
  const dispatch: AppDispatch = useDispatch();
  const {
    isAuthenticated,
    user,
    ozOrGramsQuantityUnit,
    retiringCertificateId,
    selectedStandardId,
    selectedStandardType,
    uniqueStandardsFromBalances,
  } = useCertificesSlicesCombiner();

  let selectedStandard = undefined;
  if (selectedStandardId) {
    selectedStandard = uniqueStandardsFromBalances.find(
      (s) => s._id === selectedStandardId
    );
  }

  const dataGridColumns: GridColDef[] = [
    {
      field: 'pdf',
      headerName: 'PDF',
      editable: false,
      width: 60,
      filterable: false,
      sortable: false,
      disableExport: true,
      renderCell: (params) => (
        <div style={{ textAlign: 'center' }}>
          {!params.row.cancelled && (
            <Tooltip
              title={'Download certificate'}
              placement="bottom"
              enterDelay={100}
            >
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                style={{ backgroundColor: '#FFFFFF' }}
                onClick={async () =>
                  await dispatch(handleGenerateCertificatePdf(params.row))
                }
              >
                <img
                  src={certificateIcon}
                  alt={'Download certificate ' + params.row.id}
                  title={'Download certificate ' + params.row.id}
                  style={{ width: 22, height: 22 }}
                />
              </Fab>
            </Tooltip>
          )}
        </div>
      ),
    },

    {
      field: 'clientName',
      headerName: 'For',
      editable: false,
      width: 200,
      valueGetter: getCertificateClientName,
      renderCell: (params: any) => (
        <CertificateTableCell
          value={params.row?.client?.name}
          cancelled={params.row?.cancelled}
        />
      ),
    },

    {
      field: 'creationDate',
      headerName: 'Date',
      editable: false,
      width: 120,
      type: 'date',
      valueFormatter: ({ value }) =>
        value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '',
      renderCell: (params: GridRenderCellParams<string>) => (
        <DateCell
          date={params.row.creationDate}
          cancelled={params.row.cancelled}
        />
      ),
    },
    {
      field: 'productIdValue',
      headerName: 'Product Id',
      editable: false,
      width: 200,
      valueGetter: getCertificateProductId,
      renderCell: (params) => {
        if (params.row?.cancelled)
          return (
            <CertificateTableCell
              value={params.row?.origin?.productId}
              cancelled={params.row?.cancelled}
            />
          );

        return (
          <LinkOpenProductDialog
            product={params?.row?.origin}
            month={params?.row?.month}
            year={params?.row?.year}
            text={params?.row?.origin?.productId}
          />
        );
      },
    },
    {
      field: 'productNameValue',
      headerName: 'Product',
      editable: false,
      width: 200,
      valueGetter: getCertificateOriginName,
      renderCell: (params) => {
        if (params.row?.cancelled)
          return (
            <CertificateTableCell
              value={params.row?.origin?.displayedName}
              cancelled={params.row?.cancelled}
            />
          );

        return (
          <LinkOpenProductDialog
            product={params?.row?.origin}
            month={params?.row?.month}
            year={params?.row?.year}
            text={params?.row?.origin?.displayedName}
          />
        );
      },
    },
    ...getDynamicColumns(
      selectedStandard,
      selectedStandardType,
      uniqueStandardsFromBalances
    ),
    {
      field: 'volume',
      headerName: quantityTitle,
      editable: false,
      width: 140,
      type: 'number',
      valueGetter: (params) => {
        const _quantity = getCertificateQuantity(params);
        const productUnit = getCertificateOriginUnit(params);
        if (
          displayPlatinumUI &&
          productUnit === enums.Units.GRAMS &&
          ozOrGramsQuantityUnit !== enums.Units.GRAMS
        ) {
          return convertGramsInOz(_quantity);
        }
        return _quantity;
      },
      renderCell: (params) => (
        <CertificateTableCell
          value={certificateQuantityRenderer(params)}
          cancelled={params.row?.cancelled}
        />
      ),
    },
    {
      field: 'unit',
      headerName: 'Unit',
      editable: false,
      width: 80,
      valueGetter: (params) => {
        const productUnit = getCertificateOriginUnit(params);
        if (
          displayPlatinumUI &&
          productUnit === enums.Units.GRAMS &&
          ozOrGramsQuantityUnit !== enums.Units.GRAMS
        ) {
          return 'oz';
        }
        return productUnit;
      },
      renderCell: (params) => (
        <CertificateTableCell
          value={params.value}
          cancelled={params.row?.cancelled}
        />
      ),
    },

    {
      field: 'monthAndYear',
      headerName: periodTitle,
      editable: false,
      width: 120,
      type: 'text',
      sortComparator: monthYearComparator,
      valueGetter: getCertificateDateFromMonthAndYearText,
      renderCell: (params: any) => (
        <CertificateTableCell
          value={params.value}
          cancelled={params.row?.cancelled}
        />
      ),
    },

    {
      field: 'blockchainProof',
      headerName: 'Blockchain proof',
      editable: false,
      width: 140,
      filterable: false,
      sortable: false,
      disableExport: true,
      renderCell: (params) => (
        <TransactionAndSecretOptions
          txHash={params.row.txHash}
          onSecretClick={async () => {
            await dispatch(handleOpenSecretWordDialog(params.row));
          }}
        />
      ),
    },
    ...(checkUserIsSuperAdmin(isAuthenticated, user) ||
    checkUserHasRight(isAuthenticated, user, RightReferences.CANCEL)
      ? [
          {
            field: 'cancel',
            headerName: 'Remove',
            editable: false,
            width: 80,
            filterable: false,
            sortable: false,
            disableExport: true,
            renderCell: (params: any) => (
              <div>
                {retiringCertificateId === params.row.id && (
                  <Button>
                    <CircularProgress size={16} style={{ marginLeft: 10 }} />
                  </Button>
                )}
                {retiringCertificateId !== params.row.id &&
                  !params.row.cancelled && (
                    <Tooltip
                      title={'Remove certificate'}
                      placement="bottom"
                      enterDelay={100}
                    >
                      <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        style={{ backgroundColor: '#FFFFFF' }}
                        onClick={() =>
                          dispatch(
                            handleOpenCancelCertificateFormDialog(params.row)
                          )
                        }
                      >
                        <img
                          src={trashIcon}
                          alt={'Remove'}
                          title={'Remove'}
                          style={{ width: 20, height: 20 }}
                        />
                      </Fab>
                    </Tooltip>
                  )}
              </div>
            ),
          },
        ]
      : []),
  ];

  return dataGridColumns;
};

export default CertificatesColumns;
