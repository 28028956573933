import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { DataGridPro as Table } from '@mui/x-data-grid-pro';
import {
  displayAggregationTransactionsModal,
  getProductAggregationsStart,
} from 'actions';
import LoadAll from 'components/commons/LoadAll/LoadAll';
import AggregationCardContext from 'components/Product/Context/AggregationCardContext';
import GridToolbar from 'components/Product/CustomComponents/Toolbar/AggregationToolbar/CustomTableToolbar/GridToolbar';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import {
  FILTER_MODE_CLIENT_SIDE,
  PAGINATION_MODE_SERVER_SIDE,
} from 'components/Transactions/utils/constants';
import { AppDispatch } from 'redux/store';
import { getColumns } from './utils/columns';
import usePagination from 'components/Product/Redux/CustomHooks/usePagination';
import { useNavigate, useParams } from 'react-router';

const AggregationTable = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useProductSlicesCombiner();
  const { page, pageSize, handlePageChange, handlePageSizeChange } =
    usePagination();
  const { assetId } = useParams();

  const { data, title } = useContext(AggregationCardContext);

  const cleanedTitle = (title as string).replace(/History/g, '').trim();

  const {
    loadingProductAggregations,
    productAggregations,
    totalCountAggregations,
    currentProduct,
  } = useProductSlicesCombiner();

  const handleOpenTransactionDialog = (aggregationId: string) => {
    dispatch(displayAggregationTransactionsModal(aggregationId));
  };

  const fetchAggregationsData = useCallback(async () => {
    const productMongoId = assetId ?? (currentProduct as any)._id;
    await dispatch(getProductAggregationsStart(productMongoId, page, pageSize));
  }, [assetId, page, pageSize]);

  useEffect(() => {
    if (pageSize > 100) {
      if (!isAuthenticated) {
        navigate('/');
      } else {
        fetchAggregationsData();
      }
    }
  }, [pageSize]);
  const getRowId = (row: any) => row.mostRecentAggregation?._id || row._id;

  return (
    <Table
      autoHeight
      loading={loadingProductAggregations as boolean}
      pagination
      paginationMode={PAGINATION_MODE_SERVER_SIDE}
      filterMode={FILTER_MODE_CLIENT_SIDE}
      columns={getColumns(handleOpenTransactionDialog)}
      getRowId={getRowId}
      rows={data}
      components={{
        LoadingOverlay: LinearProgress,
        Toolbar: GridToolbar,
        Footer: () =>
          assetId ? (
            <LoadAll
              onClick={() =>
                handlePageSizeChange(totalCountAggregations as number)
              }
              loadedRows={(productAggregations as any[]).length}
              totalRows={totalCountAggregations as number}
              typeOfData={`${cleanedTitle} aggregations`}
            />
          ) : null,
      }}
      page={page}
      pageSize={pageSize}
      rowCount={totalCountAggregations}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
    />
  );
};

export default AggregationTable;
