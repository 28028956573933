import { enums } from '../enums';

const OneMmbtuInWh = 293071.069444444;
//1 MMBtu = 293071.07 Wh
//1 MMBtu = 293071.069444444 Wh
//1 MMBtu = 293,071069444444 kWh
const OneMmbtuInKwh = OneMmbtuInWh / 1000;
const OneOzInGrams = 31.1035; //Specific conversation rate for platinum, in other cases let OneOzInGrams = 28.3495

export const convertGramsInOz = (quantityInGrams: number): number => {
  const quantityInOz = quantityInGrams / OneOzInGrams;
  return quantityInOz;
};

export const convertOzInGrams = (quantityInOz: number): number => {
  const quantityInGrams = quantityInOz * OneOzInGrams;
  return quantityInGrams;
};

export const convertWhInMmbtu = (volumeInWh: number): number => {
  const volumeInMmbtu = volumeInWh / OneMmbtuInWh;
  return volumeInMmbtu;
};

export const convertWhInMmbtuInt = (volumeInWh: string): number => {
  const volumeInMmbtu = parseFloat(volumeInWh) / OneMmbtuInWh;
  return volumeInMmbtu;
};

export const convertKwhInMmbtu = (volumeInKwh: string): number => {
  const volumeInMmbtu = parseFloat(volumeInKwh) / OneMmbtuInKwh;
  return volumeInMmbtu;
};

export const convertKwhInMmbtuInt = (volumeInKwh: string): number => {
  const volumeInMmbtu = parseInt(volumeInKwh) / OneMmbtuInKwh;
  return volumeInMmbtu;
};

export const convertMmbtuInKwh = (volumeInMmbtu: number): number => {
  const volumeInKwh = volumeInMmbtu * OneMmbtuInKwh;
  return volumeInKwh;
};

export const convertMmbtuInKwhInt = (volumeInMmbtu: string): number => {
  const volumeInKwh = parseInt(volumeInMmbtu) * OneMmbtuInKwh;
  return volumeInKwh;
};

export const convertWhInkWh = (volumeInWh: any): number => {
  //1 kWh = 1000 wh
  const volumeInKwh = parseFloat(volumeInWh) / 1000;
  return volumeInKwh;
};

export const convertWhInGWh = (volumeInWh: string): number => {
  //1 GWh = 1 000 000 000 wh
  const volumeInGwh = parseFloat(volumeInWh) / 1000000000;
  return volumeInGwh;
};

export const convertBytesInOctetsString = (aSize: number): string => {
  if (typeof aSize !== 'number') return '';
  const def = [
    { octetsBounds: 1, unit: 'octets' },
    { octetsBounds: 1024, unit: 'ko' },
    { octetsBounds: 1024 * 1024, unit: 'Mo' },
    { octetsBounds: 1024 * 1024 * 1024, unit: 'Go' },
    { octetsBounds: 1024 * 1024 * 1024 * 1024, unit: 'To' },
  ];
  for (let i = 0; i < def.length; i++) {
    if (aSize < def[i].octetsBounds)
      return (
        (aSize / def[i - 1].octetsBounds).toFixed(2) + ' ' + def[i - 1].unit
      );
  }
  return '';
};

export const getMostUsedUnit = (units: string[]) => {
  let unit = enums.Units.KWH;
  let kwhCount = 0;
  let mmbtuCount = 0;
  let gramsCount = 0;
  let tonsCount = 0;
  let kgCount = 0;
  let voyagesCount = 0;

  units.forEach((unit) => {
    switch (unit) {
      case enums.Units.KWH:
        kwhCount++;
        break;
      case enums.Units.MMBTU:
        mmbtuCount++;
        break;
      case enums.Units.GRAMS:
        gramsCount++;
        break;
      case enums.Units.TONS:
        tonsCount++;
        break;
      case enums.Units.KG:
        kgCount++;
        break;
      case enums.Units.VOYAGES:
        voyagesCount++;
        break;
      default:
        break;
    }
  });

  const counts = [
    kwhCount,
    mmbtuCount,
    gramsCount,
    tonsCount,
    kgCount,
    voyagesCount,
  ];

  const maxCount = Math.max(...counts);

  if (maxCount === 0) {
    return enums.Units.KWH;
  }

  const maxCountIndex = counts.indexOf(maxCount);

  switch (maxCountIndex) {
    case 0:
      unit = enums.Units.KWH;
      break;
    case 1:
      unit = enums.Units.MMBTU;
      break;
    case 2:
      unit = enums.Units.GRAMS;
      break;
    case 3:
      unit = enums.Units.TONS;
      break;
    case 4:
      unit = enums.Units.KG;
      break;
    case 5:
      unit = enums.Units.VOYAGES;
      break;
    default:
      break;
  }

  return unit;
};
