import { ClassNames } from '@emotion/react';
import { Fab, Tooltip } from '@mui/material';
import useBalancesChildComponentsSlices from 'components/Balances/Redux/CustomHooks/useBalancesChildComponentsSlices';
import LinkOpenProductDialog from 'components/commons/LinkOpenProductDialog/LinkOpenProductDialog';
import { RightReferences } from 'enums/RightReferences';
import { useTranslation } from 'react-i18next';
import { checkUserHasRight } from 'utils/user/checkRights';

import depositIcon from '../../../../assets/images/icons/deposit.png';
import transferIcon from '../../../../assets/images/icons/money-transfer-v3.png';
import { toolbarStyles } from '../../../../assets/styles/toolbarStyles';
import { convertGramsInOz } from '../../../../utils/convert';
import {
  getFromName,
  getProductId,
  getOriginName,
  getDateFromMonthAndYearText,
  monthYearComparator,
  getOriginUnit,
  getQuantity,
  getAllocatedQuantity,
  quantityRenderer,
} from '../../../../utils/datagridHelpers';
import { checkUserIsSuperAdmin } from '../../../../utils/user/checkAdmin/CheckAdmin';

const classes = toolbarStyles;
let displayPlatinumUI = process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';

const BalanceColumns = (
  activateOzConversion,
  handleOpenTransferFormDialog,
  handleOpenAllocateFormDialog,
  filterOperators,
  passedDataGridColumns
) => {
  const { t: translate } = useTranslation();
  const { user, isAuthenticated } = useBalancesChildComponentsSlices();
  const isSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);

  const allDataGridColumns = [
    {
      field: '_productIdValue',
      headerName: 'Product Id',
      editable: false,
      width: 170,
      filterOperators,
      valueGetter: getProductId,
      renderCell: (params) => {
        return (
          <LinkOpenProductDialog
            product={params?.row?._origin}
            month={params?.row?._month}
            year={params?.row?._year}
            text={params?.row?._origin?.productId}
          />
        );
      },
    },
    {
      field: '_productValue',
      headerName: 'Product',
      editable: false,
      width: 200,
      valueGetter: getOriginName,
      renderCell: (params) => {
        return (
          <LinkOpenProductDialog
            product={params?.row?._origin}
            month={params?.row?._month}
            year={params?.row?._year}
            text={params?.row?._origin?.displayedName}
          />
        );
      },
    },
    ...passedDataGridColumns,
    {
      field: '_monthAndYear',
      headerName: displayPlatinumUI ? 'Period (Half Year)' : 'Period (Month)',
      editable: false,
      width: 140,
      type: 'string',
      sortComparator: monthYearComparator,
      valueGetter: getDateFromMonthAndYearText,
    },
    {
      field: '_quantity',
      headerName: 'Open',
      editable: false,
      width: 100,
      type: 'number',
      valueGetter: (params) => {
        let _quantity = getQuantity(params);
        return activateOzConversion ? convertGramsInOz(_quantity) : _quantity;
      },
      renderCell: quantityRenderer,
    },
    {
      field: '_unit',
      headerName: 'Unit',
      editable: false,
      width: 120,
      valueGetter: (params) => {
        let unitToDisplay = getOriginUnit(params);
        return activateOzConversion ? 'oz' : unitToDisplay;
      },
    },
    {
      field: '_organizationsInTransferMenu',
      headerName: 'Transfer',
      editable: false,
      width: 100,
      filterable: false,
      sortable: false,
      disableExport: true,
      hide: !checkUserHasRight(isAuthenticated, user, RightReferences.TRANSFER),
      renderCell: (params) => {
        let quantityInKWhOrMMBTUOrKg = params?.row?._quantity;
        if (quantityInKWhOrMMBTUOrKg <= 0) return false;
        return (
          <ClassNames>
            {({ css }) => (
              <div style={{ textAlign: 'center' }}>
                <Tooltip
                  title={translate('Transfer certificates')}
                  placement="bottom"
                  enterDelay={100}
                >
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    className={css(classes.margin)}
                    style={{ backgroundColor: '#FFFFFF' }}
                    onClick={() => {
                      handleOpenTransferFormDialog(
                        params.row._origin,
                        params.row._from,
                        params.row._organizationsInTransferMenu,
                        params.row._organizationsInTransferMenu[0],
                        params.row._year,
                        params.row._month,
                        quantityInKWhOrMMBTUOrKg
                      );
                    }}
                  >
                    <img
                      src={transferIcon}
                      alt={'Transfer certificates'}
                      title={'Transfer certificates'}
                      style={{ width: 22, height: 22 }}
                    />
                  </Fab>
                </Tooltip>
              </div>
            )}
          </ClassNames>
        );
      },
    },
    {
      field: '_organizationsInAllocateMenu',
      headerName: 'Retire',
      editable: false,
      width: 100,
      filterable: false,
      sortable: false,
      disableExport: true,
      hide: !checkUserHasRight(isAuthenticated, user, RightReferences.RETIRE),
      renderCell: (params) => {
        let quantityInKWhOrMMBTUOrKg = params?.row?._quantity;
        if (quantityInKWhOrMMBTUOrKg <= 0) return false;
        return (
          <ClassNames>
            {({ css }) => (
              <div style={{ textAlign: 'center' }}>
                <Tooltip
                  title={translate('Retire certificates')}
                  placement="bottom"
                  enterDelay={100}
                >
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    className={css(classes.margin)}
                    style={{ backgroundColor: '#FFFFFF' }}
                    onClick={() => {
                      handleOpenAllocateFormDialog(
                        params.row._origin,
                        params.row._from,
                        params.row._organizationsInAllocateMenu,
                        params.row._organizationsInAllocateMenu[0],
                        params.row._year,
                        params.row._month,
                        quantityInKWhOrMMBTUOrKg
                      );
                    }}
                  >
                    <img
                      src={depositIcon}
                      alt={'Retire certificates'}
                      title={'Retire certificates'}
                      style={{ width: 22, height: 22 }}
                    />
                  </Fab>
                </Tooltip>
              </div>
            )}
          </ClassNames>
        );
      },
    },
    {
      field: '_allocatedQuantity',
      headerName: `Retired`,
      editable: false,
      width: 100,
      type: 'number',
      valueGetter: (params) => {
        let _allocatedQuantity = getAllocatedQuantity(params);
        return activateOzConversion
          ? convertGramsInOz(_allocatedQuantity)
          : _allocatedQuantity;
      },
      renderCell: quantityRenderer,
    },

    {
      field: '_fromName',
      headerName: 'Owner',
      editable: false,
      forAdminOnly: true,
      width: 200,
      valueGetter: getFromName,
    },
  ];
  const dataGridColumns = allDataGridColumns.filter(
    (c) => !(c.forAdminOnly && !isSuperAdmin)
  );
  return dataGridColumns;
};

export default BalanceColumns;
