import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import ReactJson from 'react18-json-view';

const DataPreviewModal = ({ open, loading, data, onClose }: any) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>Data Preview</DialogTitle>
    <DialogContent>
      {loading ? (
        <CircularProgress />
      ) : data ? (
        <ReactJson src={data} />
      ) : (
        <p>No data available</p>
      )}
    </DialogContent>
  </Dialog>
);

export default DataPreviewModal;
