import React from 'react';
import { useTheme, ClassNames } from '@emotion/react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartJSTooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { enums } from '../../enums';
import { convertGramsInOz } from '../../utils/convert';
import { defaultLabelsOnThreeYears } from './ProductionChart/constants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartJSTooltip,
  Legend
);

let ProductionChart = ({
  currentProduct,
  graphDataProducer,
  ozOrGramsQuantityUnit,
}) => {
  const classes = useTheme();

  if (graphDataProducer && !graphDataProducer?.barChartData) {
    return (
      <ClassNames>
        {({ css }) => (
          <CircularProgress size={24} className={css(classes.buttonProgress)} />
        )}
      </ClassNames>
    );
  }

  const unit =
    currentProduct && currentProduct.unit
      ? currentProduct.unit
      : enums.Units.KWH;

  const displayedName = currentProduct?.displayedName;
  let displayedUnit = unit;

  const LIGHT_ORANGE_ALPHA_LEVEL_4 = 'rgba(248,119,51,0.9)';
  const LIGHT_ORANGE_OPAQUE = 'rgba(248,119,51,1)';

  let datasets = [];
  let data = {};

  let quantityType =
    process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum'
      ? 'Quantity'
      : 'Volume';

  let barChartData = graphDataProducer?.barChartData?.data?.map((d) => d);

  if (
    process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum' &&
    unit === enums.Units.GRAMS &&
    ozOrGramsQuantityUnit !== enums.Units.GRAMS
  ) {
    barChartData = graphDataProducer?.barChartData?.data?.map((v) =>
      convertGramsInOz(v)
    );
    displayedUnit = ozOrGramsQuantityUnit;
  } else {
    barChartData = graphDataProducer?.barChartData?.data?.map((d) => d);
  }

  datasets = [
    {
      label: `${quantityType} delivered by ${displayedName}`,
      backgroundColor: LIGHT_ORANGE_ALPHA_LEVEL_4,
      borderColor: LIGHT_ORANGE_OPAQUE,
      borderWidth: 1,
      hoverBackgroundColor: LIGHT_ORANGE_OPAQUE,
      hoverBorderColor: LIGHT_ORANGE_OPAQUE,
      data: barChartData,
    },
  ];

  data = {
    labels: graphDataProducer?.barChartLabels || defaultLabelsOnThreeYears,
    datasets,
  };

  const options = {
    responsive: true,
    layout: {
      padding: 20,
    },
    scales: {
      y: {
        ticks: {
          // On the y axis, display kWh instead of Wh if unit = 'kWh'
          callback: function (value) {
            let valueDisplayed = Math.round(value);
            return valueDisplayed;
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItem) => {
            if (tooltipItem[0]) {
              return tooltipItem[0].label;
            } else {
              return null;
            }
          },
          label: (tooltipItem) => {
            let dataDisplayed = '';
            let volume = Math.round(tooltipItem.raw);
            //endpoints returns measure in Wh or MMBTU or grams
            dataDisplayed =
              tooltipItem.dataset.label + ' : ' + volume + ' ' + displayedUnit;
            return dataDisplayed;
          },
        },
      },
    },
  };

  return <Bar options={options} data={data} width={1200} height={350} />;
};

export default ProductionChart;
