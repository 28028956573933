import Link from '@mui/material/Link';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import DateCell from 'components/commons/DateCell';

import LinkCellContent from '../../../../../../commons/LinkCellContent/LinkCellContent';
import { DynamicDataUsageStatus } from './constants';
import BlockchainProofsMenu from 'components/Product/CustomComponents/Card/AggregationCard/AggregationContent/BlockchainProofs/BlockchainProofsMenu';

const issuedFormatter = ({ value }: GridValueFormatterParams) => {
  return value?.length > 0 ? 'Yes' : 'No';
};

const userFormatter = ({ value }: GridValueFormatterParams) => {
  return value?.username ? value.username : '-';
};
const measureFormatter = ({ value }: GridValueFormatterParams) => {
  if (typeof value === 'number') {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);
  } else return value;
};

export const getColumns = (handleOpenTransactionDialog: any): GridColDef[] => {
  return [
    {
      field: 'source',
      headerName: 'Source',
      editable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams<string>) => {
        return <LinkCellContent value={params?.row?.source} />;
      },
    },
    {
      field: 'creation_date',
      headerName: 'Creation date',
      editable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DateCell date={params?.row?.creation_date} />
      ),
    },
    {
      field: 'start_date',
      headerName: 'Start date',
      editable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DateCell date={params?.row?.start_date} />
      ),
    },
    {
      field: 'end_date',
      headerName: 'End date',
      editable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DateCell date={params?.row?.end_date} />
      ),
    },
    {
      field: 'measure',
      headerName: 'Current measure',
      editable: false,
      type: 'number',
      width: 240,
      renderCell: (params: GridRenderCellParams<any>) => {
        const { measure } = params.row;
        const formattedValue = measureFormatter({
          value: measure,
        } as GridValueFormatterParams);
        return <LinkCellContent value={formattedValue} />;
      },
    },
    {
      field: 'added_measure',
      headerName: 'Added measure',
      type: 'number',
      editable: false,
      width: 120,
    },
    { field: 'unit', headerName: 'Unit', editable: false, width: 180 },
    {
      field: 'transactionIds',
      headerName: 'Status',
      editable: false,
      width: 60,
      valueFormatter: issuedFormatter,
      renderCell: (params: GridRenderCellParams<string>) => {
        let status = '';
        if (params?.row?.usage_status === DynamicDataUsageStatus.ISSUED)
          status = 'Issued';
        else if (params?.row?.usage_status === DynamicDataUsageStatus.REPLACED)
          status = 'Replaced';
        return (
          <Link
            component="button"
            variant="body2"
            onClick={(event: any) => {
              event.ignore = true;
              handleOpenTransactionDialog(params?.row?._id);
            }}
            style={{ textDecoration: 'none' }}
          >
            {status}
          </Link>
        );
      },
    },
    {
      field: 'source_id',
      headerName: 'Source Id',
      editable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams<string>) => {
        return <LinkCellContent value={params?.row?.source_id} />;
      },
    },
    {
      field: 'period_type',
      headerName: 'Period',
      editable: false,
      width: 100,
    },
    {
      field: 'period_number',
      headerName: 'Period number',
      editable: false,
      width: 100,
    },
    {
      field: 'moment_timezone',
      headerName: 'Timezone',
      editable: false,
      width: 160,
    },
    {
      field: 'user',
      headerName: 'User',
      editable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return params?.row?.user ? params?.row?.user.username : '-';
      },
      valueFormatter: userFormatter,
      width: 200,
    },
    {
      field: 'menu',
      headerName: 'Blockchain proofs',
      editable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams<string>) => {
        return <BlockchainProofsMenu row={params.row} />;
      },
    },
  ];
};
