import { useEffect } from 'react';

import { getProductStart } from 'actions';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { AppDispatch } from 'redux/store';

import useProductSlicesCombiner from '../Slices/useProductSlicesCombiner';

export const useProduct = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { assetId } = useParams();
  const { isAuthenticated, currentProduct } = useProductSlicesCombiner();

  useEffect(() => {
    const initComponent = async () => {
      if (!isAuthenticated) navigate('/');
      else {
        const productMongoId = assetId ?? (currentProduct as any)._id;
        dispatch(getProductStart(productMongoId));
      }
    };
    initComponent();
  }, []);
};
