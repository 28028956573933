import React, { useState, useCallback } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Tooltip, IconButton, Menu } from '@mui/material';
import MenuItemIcon from 'components/commons/MenuItemIcon/MenuItemIcon';
import blockchainLogo from '../../../../../assets/images/blockchain.png';
import lockIcon from '../../../../../assets/images/icons/padlock.png';
import { openErrorSnack } from 'actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { blockchainExplorerUrl } from 'config/blockchainExplorerConfig';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';
import useCertificesSlicesCombiner from 'components/Certificates/Redux/Slices/useCertificatesSlicesCombiner';
import { RightReferences } from 'enums/RightReferences';
import { CrudPermissions } from 'enums/CrudPermissions';

type TransactionAndSecretOptionsProps = {
  txHash: string | null;
  onSecretClick: () => void;
};

const TransactionAndSecretOptions: React.FC<
  TransactionAndSecretOptionsProps
> = ({ txHash, onSecretClick }) => {
  const dispatch: AppDispatch = useDispatch();
  const { isAuthenticated, user } = useCertificesSlicesCombiner();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleTransactionClick = useCallback(() => {
    if (txHash) {
      window.open(`${blockchainExplorerUrl}/tx/${txHash}`, '_blank');
    } else {
      dispatch(openErrorSnack('Blockchain transaction not available.'));
    }
    handleClose();
  }, [txHash, handleClose]);

  const canViewSecret =
    checkUserIsSuperAdmin(isAuthenticated, user) ||
    checkUserHasRight(isAuthenticated, user, RightReferences.CERTIFICATES, [
      CrudPermissions.VIEW,
    ]);

  return (
    <>
      <Tooltip title="Click to see more actions" placement="bottom">
        <IconButton aria-label="open more menu" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id="blockchain-secret-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItemIcon
          key="transaction"
          icon={blockchainLogo}
          onClick={handleTransactionClick}
          title="Transaction"
        />
        {canViewSecret && (
          <MenuItemIcon
            key="secret"
            icon={lockIcon}
            onClick={() => {
              onSecretClick();
              handleClose();
            }}
            title="Secret"
          />
        )}
      </Menu>
    </>
  );
};

export default TransactionAndSecretOptions;
