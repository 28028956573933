import { Fab } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import { blockchainExplorerUrl } from 'config/blockchainExplorerConfig';
import downloadFileWithCookie from 'utils/fetch/downloadFile';

import blockchainLogo from '../../../../../../../../assets/images/blockchain.png';
import copyIcon from '../../../../../../../../assets/images/icons/copy.png';
import sourceFileIcon from '../../../../../../../../assets/images/icons/green-file.png';

export const getDataGridAssetMeteringColumns = () => {
  return [
    {
      field: 'creation_date',
      headerName: 'Creation date',
      editable: false,
      width: 200,
    },
    {
      field: 'measure',
      headerName: 'Current measure',
      editable: false,
      width: 240,
    },
    {
      field: 'added_measure',
      headerName: 'Added measure',
      editable: false,
      width: 100,
    },
    { field: 'unit', headerName: 'Unit', editable: false, width: 180 },
    {
      field: 'fileName',
      headerName: 'Source file',
      editable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <TableCell style={{ textAlign: 'center' }}>
            {params.row.fileName && (
              <Tooltip
                title={'Download source'}
                placement="bottom"
                enterDelay={100}
              >
                <Fab
                  size="small"
                  color="primary"
                  aria-label="add"
                  style={{ backgroundColor: '#FFFFFF' }}
                  onClick={() => downloadFileWithCookie(params.row.fileName)}
                >
                  <img
                    src={sourceFileIcon}
                    alt={'Download source file'}
                    title={'Download source file'}
                    style={{
                      width: 22,
                      height: 22,
                      marginTop: 6,
                      marginLeft: 2,
                    }}
                  />
                </Fab>
              </Tooltip>
            )}
          </TableCell>
        );
      },
    },
    {
      field: 'txHash',
      headerName: 'txHash',
      editable: false,
      width: 100,
      renderCell: (params) => (
        <TableCell style={{ textAlign: 'center' }}>
          {params.row.txHash && (
            <Tooltip
              title={'Check transaction'}
              placement="bottom"
              enterDelay={100}
            >
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                style={{ backgroundColor: '#FFFFFF' }}
              >
                <a
                  href={`${blockchainExplorerUrl}/tx/${params.row.txHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={blockchainLogo}
                    alt="check transaction"
                    title="check transaction"
                    style={{
                      width: 22,
                      height: 22,
                      marginTop: 6,
                      marginLeft: 2,
                    }}
                  />
                </a>
              </Fab>
            </Tooltip>
          )}
        </TableCell>
      ),
    },
    {
      field: 'dataHash',
      headerName: 'File hash',
      editable: false,
      width: 100,
      renderCell: (params) => (
        <TableCell style={{ textAlign: 'center' }}>
          {params.row.dataHash && (
            <Tooltip
              title={'Click to copy file hash'}
              placement="bottom"
              enterDelay={100}
            >
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                style={{ backgroundColor: '#FFFFFF' }}
                onClick={() => {
                  navigator.clipboard.writeText(params.row.dataHash);
                }}
              >
                <img
                  src={copyIcon}
                  alt="Copy hash"
                  title="Copy hash"
                  style={{
                    width: 19,
                    height: 19,
                    marginTop: 0,
                    marginLeft: 2,
                  }}
                />
              </Fab>
            </Tooltip>
          )}
        </TableCell>
      ),
    },
    { field: 'source', headerName: 'Source', editable: false, width: 200 },
    {
      field: 'source_id',
      headerName: 'Source Id',
      editable: false,
      width: 200,
    },
    {
      field: 'start_date',
      headerName: 'Start date',
      editable: false,
      width: 200,
    },
    {
      field: 'end_date',
      headerName: 'End date',
      editable: false,
      width: 200,
    },
    {
      field: 'measure_original',
      headerName: 'Original measure',
      editable: false,
      width: 80,
    },
    {
      field: 'unit_original',
      headerName: 'Original unit',
      editable: false,
      width: 80,
    },
    {
      field: 'period_type',
      headerName: 'Period',
      editable: false,
      width: 100,
    },
    {
      field: 'period_number',
      headerName: 'Period number',
      editable: false,
      width: 100,
    },
  ];
};
